@mixin breakpoint($point) {
    @if $point==small {
        @media (min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    }
    @else if $point==medium {
        @media (min-width: 1024px) and (max-width: 1200px) {
            @content;
        }
    }
    @else if $point==large {
        @media (min-width: 1200px) and (max-width: 1440px) {
            @content;
        }
    }
    @else if $point==x-large {
        @media (min-width: 1441px) and (max-width: 1970px) {
            @content;
        }
    }
}