journey-courses div[course-container] div[course-list] div[card]>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 73px;
}

journey-courses div[course-container] div[course-list] h3 {
    margin-bottom: 5px;
    margin-left: 5px;
}

journey-courses div[course-container] div[course-list-details] div[upper] {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
    padding: 20px;
    min-height: fit-content;
}

journey-courses div[course-container] div[course-list] h3 {
    margin-bottom: 5px;
    margin-left: 5px;
}

journey-home div[experience] div[content]>p[title] {
    display: inline-block;
}

journey-home div[app]>p[title] {
    display: inline-block;
}

journey-footer footer[mode='close'] {
    height: 79px;
}

journey-home div[experience] div[video] {
    padding: 38px 0;
}

journey-courses div[course-container] div[course-list] {
    height: calc(100vh - 430px);
}

journey-courses div[course-container] div[course-list-details] {
    height: calc(100vh - 410px);
    width:100%;
}

journey-stay {
    max-height: calc(100vh - 260px);
}

journey-flight {
    max-height: calc(100vh - 260px);
    overflow: auto;
}

journey-courses div[course-container] div[course-list-details] div[upper]>div:nth-child(2) {
    max-width: 100%;
}

journey-courses div[course-container] div[course-list-details] div[upper]>div:nth-child(4) {
    width: auto;
}




journey-home .img-responsive {
    max-width: 80%;
}

journey-courses div[course-container] div[course-list] div[card] {
    height: 75px;
}

journey-home div[experience] div[content] {
    @include breakpoint(small) {
        -ms-flex: 1 1 auto;
        width: 100%;
    }
}

journey-home div[experience] div[video] {
    @include breakpoint(small) {
        -ms-flex: 1 1 auto;
    }
}


/* ui clean up */

/*
journey-courses div[note] span:first-child,
journey-flight div[note] span:first-child,
journey-stay div[note] span:first-child {
    color: #00338D;
}
*/
div[register-course]{
    max-width:100% !important;
}
/*Ad hoc notificatio error message*/

notification {
    ad-hoc .mat-form-field-subscript-wrapper {
        margin-top: 5px !important;
    }
    /*
.mat-ink-bar{
    
    background-color: #00338d !important;
    height: 4px !important;

}
.mat-tab-label{
    font-size: 16px;
    color: #00338D;
    letter-spacing: 0;
    opacity: 1 !important;
    font-weight: bold;
}*/
}


/* Global level changes */

.mat-ink-bar {
    background-color: #00338d !important;
    height: 4px !important;
}

.mat-tab-label {
    font-size: 16px;
    letter-spacing: 0;
    opacity: 1 !important;
}

.mat-tab-label-active {
    color: #00338D;
    font-weight: bold;
}

h1[prog-head] {
    background: #fff;
    padding: 20px 25px;
    margin: 0;
}

h1[prog-title] {
    padding: 34px 25px 10px;
    margin: 0;
    font-size: 32px;
}

div[breadcrumbs] .Breadcrumb .mat-select-arrow {
    display: none;
}

amplify-sign-in {
    --label-color: white;
    --amplify-primary-color: none;
    --amplify-primary-shade: none;
    --amplify-primary-tint: none;
    --amplify-primary-contrast: white;
    --color: white;
}

amplify-authenticator {
    --padding: 0px 40px;
    --container-height: 0vh;
    --amplify-background-color: rgba(0,0,0,0);
    --amplify-secondary-color: #000000;
    --header-color: white;
    --amplify-header-color: white;
    --amplify-secondary-color: white;
    --amplify-secondary-contrast: none;
    --amplify-primary-contrast: none;
    --amplify-primary-color: white;
}

.mat-calendar-body-cell-content.mat-focus-indicator {
    font-weight: 400;
    font-size: small;
}