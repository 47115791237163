/* You can add global styles to this file, and also import other style files */


/* You can add global styles to this file, and also import other style files */

@import './theme/shared/variables';

@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import './theme/variable.scss';
@import './theme/share.scss';
@import './theme/admin.scss';
@import './theme/journey.scss';
@import './theme/overwrite.scss';

@import './theme/spacing.scss';
@import './theme/typography.scss';
@import './theme/container.scss';
@import './theme/buttons.scss';
