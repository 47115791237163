// Margin top spacing
.m-t-xs {
    margin-top: $spacing-xs;
}

.m-t-sm {
    margin-top: $spacing-sm;
}

.m-t-md {
    margin-top: $spacing-md;
}

.m-t-lg {
    margin-top: $spacing-lg;
}

.m-t-xl {
    margin-top: $spacing-xl;
}

.m-t-xxl {
    margin-top: $spacing-xxl;
}

// Margin bottom spacing
.m-b-xs {
    margin-bottom: $spacing-xs;
}

.m-b-sm {
    margin-bottom: $spacing-sm;
}

.m-b-md {
    margin-bottom: $spacing-md;
}

.m-b-lg {
    margin-bottom: $spacing-lg;
}

.m-b-xl {
    margin-bottom: $spacing-xl;
}

.m-b-xxl {
    margin-bottom: $spacing-xxl;
}

// Margin right spacing
.m-r-xs {
    margin-right: $spacing-xs;
}

.m-r-sm {
    margin-right: $spacing-sm;
}

.m-r-md {
    margin-right: $spacing-md;
}

.m-r-lg {
    margin-right: $spacing-lg;
}

.m-r-xl {
    margin-right: $spacing-xl;
}

.m-r-xxl {
    margin-right: $spacing-xxl;
}

// Margin left spacing
.m-l-xs {
    margin-left: $spacing-xs;
}

.m-l-sm {
    margin-left: $spacing-sm;
}

.m-l-md {
    margin-left: $spacing-md;
}

.m-l-lg {
    margin-left: $spacing-lg;
}

.m-l-xl {
    margin-left: $spacing-xl;
}

.m-l-xxl {
    margin-left: $spacing-xxl;
    width: 90%;
}

// Padding top spacing
.p-t-xs {
    padding-top: $spacing-xs;
}

.p-t-sm {
    padding-top: $spacing-sm;
}

.p-t-md {
    padding-top: $spacing-md;
}

.p-t-lg {
    padding-top: $spacing-lg;
}

.p-t-xl {
    padding-top: $spacing-xl;
}

.p-t-xxl {
    padding-top: $spacing-xxl;
}

// Padding bottom spacing
.p-b-xs {
    padding-bottom: $spacing-xs;
}

.p-b-sm {
    padding-bottom: $spacing-sm;
}

.p-b-md {
    padding-bottom: $spacing-md;
}

.p-b-lg {
    padding-bottom: $spacing-lg;
}

.p-b-xl {
    padding-bottom: $spacing-xl;
}

.p-b-xxl {
    padding-bottom: $spacing-xxl;
}

// Padding right spacing
.p-r-xs {
    padding-right: $spacing-xs;
}

.p-r-sm {
    padding-right: $spacing-sm;
}

.p-r-md {
    padding-right: $spacing-md;
}

.p-r-lg {
    padding-right: $spacing-lg;
}

.p-r-xl {
    padding-right: $spacing-xl;
}

.p-r-xxl {
    padding-right: $spacing-xxl;
}

// Padding left spacing
.p-l-xs {
    padding-left: $spacing-xs;
}

.p-l-sm {
    padding-left: $spacing-sm;
}

.p-l-md {
    padding-left: $spacing-md;
}

.p-l-lg {
    padding-left: $spacing-lg;
}

.p-l-xl {
    padding-left: $spacing-xl;
}

.p-l-xxl {
    padding-left: $spacing-xxl;
}

.p-10 {
    padding: $spacing-sm;
}

.p-15 {
    padding: $spacing-md;
}

.p-0 {
    padding: 0;
}

.p-l-0 {
    padding-left: 0 !important;
}

.p-t-0 {
    padding-top: 0;
}

.p-l-8 {
    padding-left: 8px !important;
}

.p-b-0 {
    padding-bottom: 0;
}

.p-sm {
    padding: $spacing-sm
}

.m-0 {
    margin: 0
}

.m-t-0 {
    margin-top: 0;
}

.m-sm {
    margin: $spacing-sm
}

.b-0 {
    border: 0;
}

.right-0 {
    right: 0;
}

.right-15 {
    right: 15px;
}

.top-0 {
    top: 0;
}

.top-5 {
    top: 5px
}

.top-10px {
    top: 10px;
}

.top-25px {
    top: 25px;
}

.width-20 {
    width: 20%;
}

.width-25 {
    width: 25%;
}

.width-50 {
    width: 50%;
}

.width-40 {
    width: 40%;
}

.width-45 {
    width: 45%;
}

.width-60 {
    width: 60%;
}

.width-80 {
    width: 80%;
}

.width-85 {
    width: 85%;
}

.width-100 {
    width: 100%;
}

.width-inherit {
    width: inherit;
}

.width-15px {
    width: 15px;
}

.bottom-0 {
    bottom: 0;
}

.top-25 {
    top: 25px;
}

.height-100 {
    height: 100%;
}

.p-md {
    padding: $spacing-md
}

.notification-history-search-box .mat-form-field-appearance-legacy .mat-form-field-label{
    padding-left: 10px;
}