
.divider {
    border-top: 1px solid  $gray-light;
    margin-top: 0;
    margin-bottom: 0;

    &.gray-lighter {
        border-top: 1px solid $gray-lighter;
    }

    &.bottom {
        border-top: 0;
        border-bottom: 1px solid  $gray-light;
    }
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}
.overflow-wrap-break-word {
    overflow-wrap: break-word;
}
.cursor-pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.margin-auto {
    margin: auto;
}

.position-absolute {
    position: absolute;
}

.display-none{
    display: none;
}

.position-relative {
    position: relative;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-y-auto {
    overflow-y: auto;
}

.line-hight-22 {
    line-height: 22px;
}

.tooltip {
    display: inline-block;
 }


 .inline-block {
     display: inline-block;
 }

 .border-radius-16 {
     border-radius: 16px;
 }

.tooltip .tooltip-text {
    visibility: hidden;
    width: 255px;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    z-index: 1;
    top: 109%;
    left: 35%;
    margin-left: -120px;
    position: absolute;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16);
    background: #FFFFFF;
}

.tooltip .tooltip-text::after {
    content: "";
    top: -11px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent #ffffff #ffffff;
    transform-origin: 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.1);
 }

.tooltip:hover .tooltip-text {
    visibility: visible;
 }

.background-color-solitude {
    background-color: $solitude;
}

.disabled {
    pointer-events:none;
    cursor: not-allowed;
    opacity: 0.5;
}
