journey-header {
    header {
        max-width: 100%;
        margin: 0 auto;
    }
    div[up] {
        display: flex;
        background-color: #00338D;
        height: 90px;
        padding: 0;
        width: 100%;
    }
    div[down] {
        display: flex;
        background: #FFF;
        align-items: center;
        span[status-current] {
            margin-right: 20px;
            font-size: 16px;
            color: #00338D;
            font-weight: bold;
        }
        div[status-upcoming] {
            display: flex;
            height: 30px;
            align-items: center;
            padding-left: 20px;
            border-left: 2px solid #ddd;
            span:first-child {
                color: #111111;
                font-size: 16px;
                font-weight: bold;
                margin-right: 5px;
            }
            span:nth-child(2) {
                font-size: 16px;
                color: #00338D;
                font-weight: bold;
                margin-right: 5px;
            }
            span:nth-child(3),
            span:nth-child(4),
            span:nth-child(5),
            span:nth-child(6) {
                font-size: 16px;
                color: #656565;
                font-weight: normal;
                margin-right: 5px;
            }
        }
    }
    div[logo] {
        flex: 1;
        align-items: center;
        .title {
            height: 90px;
            display: flex;
            align-items: center;
            width: 200px;
            >img {
                width: 56px;
                height: 29px;
                margin-left: 9px;
                cursor: pointer;
            }
            >div {
                margin-left: 25px;
                cursor: pointer;
                >span {
                    display: block;
                    font-size: 13px;
                    color: white;
                    text-transform: uppercase;
                    letter-spacing: 3px;
                }
            }
        }
    }
    div[tabs] {
        flex: 1;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        height: 90px;
        >a {
            height: 90px;
            min-width: 100px;
            align-items: center;
            display: flex;
            justify-content: center;
            text-decoration: none;
            span {
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                font-size: 16px;
                font-weight: bold;
                color: #fff;
                margin-top: 7px;
                &:last-child {
                    margin-top: 10px !important;
                }
                >i {
                    font-size: 30px;
                    margin-bottom: 5px;
                }
            }
            &.active {
                background: rgba(255, 255, 2555, 0.1);
            }
        }
        @include breakpoint(small) {
            margin-left: 20px;
            >a {
                min-width: 110px;
            }
        }
    }
    div[spacer] {
        flex: 2;
    }
    div.user-group {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: flex-end;
        margin-right: 25px;
        position: relative;
        span[user-name] {
            display: flex;
            width: 34px;
            height: 34px;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 1.5px solid #FFF;
            padding: 3px;
        }
        span {
            color: #fff;
            font-size: 16px;
            >i {
                font-size: 30px;
                color: #00338D;
            }
            img {
                display: inline-block;
                margin: 0 auto;
                border-width: 0;
                vertical-align: middle;
                width: 35px;
                height: 35px;
                border: 2px solid #fff;
                border-radius: 50%;
                position: relative;
                margin-right: 12px;
            }
            @include breakpoint(small) {
                img {
                    margin-right: 0;
                }
                >span[username] {
                    display: none;
                }
            }
            i+span {
                width: 4px;
                height: 4px;
                position: absolute;
                border-radius: 50%;
                color: red;
                border: 2px solid red;
                top: 6px;
                right: 4px;
                background-color: red;
                display: flex;
            }
        }
         :nth-child(2) {
            position: relative;
            margin-left: 10px;
        }
         :nth-child(3) {
            margin-left: 15px;
            margin-right: 10px;
        }
    }
    .spacer {
        flex: 1 1 auto;
    }
    .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 18px;
        vertical-align: middle;
        border-top: 6px solid;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        cursor: pointer;
        color: #00338D;
    }
    div[register-lakehouse] {
        max-width: 1440px;
        margin: 0 auto;
        box-sizing: border-box;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        background: #FFF;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
        border-bottom: 2px solid #00338d;
        border-top: 1px solid #ddd;
        div[head] {
            display: flex;
            padding: 10px 10px 0 0;
            >span {
                &:first-child {
                    flex: 1 1 auto;
                }
                &:last-child {
                    i {
                        color: #808080;
                        cursor: pointer;
                    }
                }
            }
        }
        div[body] {
            display: flex;
            margin-left: 80px;
            >div {
                &:first-child {
                    flex: 0 60%;
                    >div {
                        &:first-child {
                            >h3 {
                                font-size: 32px;
                                font-weight: bold;
                                color: #00338D;
                                line-height: 50px;
                                margin: 0 0 15px 0;
                            }
                        }
                        &:nth-child(2) {
                            >span {
                                font-size: 17px;
                                color: #111111;
                                line-height: 28px;
                                display: inline-block;
                                width: 85%;
                                margin-bottom: 20px;
                            }
                        }
                        &:last-child {
                            display: flex;
                            margin: 40px 0 40px 0;
                            button {
                                background: #00338D;
                                padding: 10px 20px;
                                color: #FFF;
                                font-size: 18px;
                                color: #FFFFFF;
                                letter-spacing: 0.32px;
                                img {
                                    margin-left: 15px;
                                }
                                &:first-child {
                                    margin-right: 40px;
                                }
                            }
                        }
                    }
                }
                &:nth-child(2) {
                    flex: 0 40%;
                    display: flex;
                    >div {
                        &:first-child {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            flex: 1;
                            >p {
                                margin: 5px 0;
                                font-size: 18px;
                                color: #111111;
                                >b {
                                    color: #00338D;
                                }
                            }
                        }
                        &:nth-child(2) {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            flex: 1;
                            >p {
                                margin: 5px 0;
                                font-size: 18px;
                                color: #111111;
                                >b {
                                    color: #00338D;
                                }
                            }
                        }
                        &:last-child {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            flex: 1;
                            >p {
                                margin: 5px 0;
                                font-size: 18px;
                                color: #111111;
                                >b {
                                    color: #00338D;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

journey-footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    footer[mode="open"] {
        max-width: 100%;
        margin: 0 auto;
        background: #FFF;
        display: flex;
        align-items: center;
        border-top: solid 1.5px #E5E5E5;
        >section {
            margin-left: 10px;
            >div {
                >span {
                    i {
                        font-size: 12px;
                    }
                }
            }
            div[status] {
                font-size: 14px;
                color: #00338D;
                letter-spacing: 0.25px;
                font-weight: bold;
            }
        }
        >div {
            display: flex;
            justify-content: center;
            color: #00338D;
            &:first-child {
                cursor: pointer;
                margin-left: 20px;
            }
            &:not(:first-child) {
                flex: 1;
            }
            >div {
                align-items: center;
                justify-content: center;
                display: flex;
                padding: 15px 0;
                color: #00338D;
                min-width: 100px;
                display: flex;
                flex-direction: column;
                &.stepper {
                    height: 2px;
                    background: #00338D;
                    width: 120px;
                    padding: 0;
                    align-self: center;
                    margin-left: -30px;
                    margin-top: -26px;
                    margin-right: -30px;
                }
                &.enable {
                    cursor: pointer;
                }
                >span {
                    font-size: 18px;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }
    }
    footer[mode="close"] {
        display: flex;
        justify-content: flex-end;
        min-height: 79px;
        align-items: center;
        background: #FFF;
        border-top: solid 1.5px #E5E5E5;
        border-left: solid 1.5px #E5E5E5;
        width: 110px;
        position: fixed;
        right: 15px;
        bottom: 0;
        @include breakpoint(x-large) {
            right: 7vw;
        }
        >section {
            margin-left: 10px;
            margin-right: 10px;
            >div {
                >span {
                    i {
                        font-size: 12px;
                    }
                }
            }
            div[status] {
                font-size: 14px;
                color: #00338D;
                letter-spacing: 0.25px;
                font-weight: bold;
            }
        }
        >div {
            display: flex;
            justify-content: space-around;
            padding: 10px;
            color: #00338D;
            &:first-child {
                cursor: pointer;
                span:first-child {
                    img {
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        -o-transform: rotate(180deg);
                    }
                }
            }
            &.active {
                display: none;
            }
            >div {
                align-items: center;
                justify-content: center;
                display: flex;
                padding: 10px;
                color: #00338D;
                >span {
                    font-size: 18px;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }
    }
}

div[outer-div] {
    height: calc(100vh - 230px);
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 60px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
}

journey-home {
    max-width: 1440px;
    margin: 0 auto;
    display: block;
    div[register-lakehouse] {
        max-width: 1440px;
        margin: 0 auto;
        box-sizing: border-box;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        background: #FFF;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
        border-bottom: 2px solid #00338d;
        border-top: 1px solid #ddd;
        div[head] {
            display: flex;
            height: 40px;
            align-items: center;
            margin-right: 25px;
            >span {
                &:first-child {
                    flex: 1 1 auto;
                }
                &:last-child {
                    i {
                        color: #808080;
                        cursor: pointer;
                    }
                    img {
                        height: 12px;
                        width: 12px;
                        cursor: pointer;
                    }
                }
            }
        }
        div[body] {
            display: flex;
            margin-left: 80px;
            >div {
                &:first-child {
                    flex: 0 55%;
                    >div {
                        &:first-child {
                            >h3 {
                                font-size: 32px;
                                font-weight: bold;
                                color: #00338D;
                                line-height: 50px;
                                margin: 0 0 15px 0;
                            }
                        }
                        &:nth-child(2) {
                            >span {
                                font-size: 17px;
                                color: #111111;
                                line-height: 28px;
                                display: inline-block;
                                width: 100%;
                            }
                        }
                        &:last-child {
                            display: flex;
                            margin: 40px 0 40px 0;
                            button {
                                background: #00338D;
                                padding: 10px 20px;
                                color: #FFF;
                                font-size: 18px;
                                color: #FFFFFF;
                                letter-spacing: 0.32px;
                                img {
                                    margin-left: 15px;
                                }
                                &:first-child {
                                    margin-right: 40px;
                                }
                            }
                        }
                    }
                }
                &:nth-child(2) {
                    flex: 0 45%;
                    display: flex;
                    justify-content: space-evenly;
                    >div {
                        &:first-child {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            >p {
                                margin: 5px 0;
                                font-size: 18px;
                                color: #111111;
                                >b {
                                    color: #00338D;
                                }
                            }
                        }
                        &:nth-child(2) {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            >p {
                                margin: 5px 0;
                                font-size: 18px;
                                color: #111111;
                                >b {
                                    color: #00338D;
                                }
                            }
                        }
                        &:last-child {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            >p {
                                margin: 5px 0;
                                font-size: 18px;
                                color: #111111;
                                >b {
                                    color: #00338D;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .no-margin {
        margin-top: 0 !important;
    }
    .img-responsive {
        max-width: 100%;
    }
    div[down] {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        background: #FFF;
        align-items: center;
        position: fixed;
        top: 90px;
        left: 0;
        z-index: 9999;
        width: 100%;
        border-bottom: 1.5px solid #E5E5E5;
        div[tabs-down] {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: space-around;
            height: 59px;
            margin: 0 auto;
            >a {
                height: 50px;
                align-items: center;
                display: flex;
                justify-content: center;
                text-decoration: none;
                margin: 0 40px 0 15px;
                span {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: bold;
                    color: #00338D;
                }
                &.active {
                    border-bottom: 2px solid #00338D;
                }
            }
        }
    }
    div[hero] {
        background-image: url("../assets/images/hero-bg-new.jpg");
        background-size: cover;
        height: 572px;
        position: relative;
        @include breakpoint(small) {
            margin-top: 0;
        }
        @include breakpoint(medium) {
            margin-top: 0;
        }
        div[greeting] {
            position: absolute;
            top: 80px;
            left: 80px;
            width: 554px;
            height: 358px;
            background: rgba(0, 46, 127, 0.8) !important;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            color: #fff;
            >p[welcome] {
                font-size: 16px;
                letter-spacing: 0.67px;
                margin: 25px 0 0 0;
                position: relative;
                text-transform: uppercase;
                font-weight: bold;
                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    width: 55px;
                    border-top: solid 2px #fff;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &::before {
                    margin-left: -85px;
                }
                &::after {
                    margin-left: 30px;
                }
            }
            >h3 {
                font-size: 50px;
                font-weight: bold;
                color: #FFFFFF;
                letter-spacing: -0.2px;
                text-align: center;
                line-height: 65px;
                margin: 32px 0;
            }
            p:nth-of-type(2) {
                font-size: 26px;
                letter-spacing: -0.36px;
                margin: 0 0 10px;
                display: table;
            }
            span {
                font-size: 20px;
                letter-spacing: 0;
            }
            @include breakpoint(medium) {
                width: 450px;
                height: 250px;
                top: 80px;
                >h3 {
                    font-size: 36px;
                    font-weight: bold;
                    line-height: 48px;
                    margin: 15px 0;
                }
                p:nth-of-type(2) {
                    font-size: 18px;
                }
                span {
                    font-size: 14px;
                }
            }
            @include breakpoint(small) {
                width: 450px;
                height: 250px;
                top: 40px;
                height: 230px;
                >h3 {
                    font-size: 36px;
                    font-weight: bold;
                    line-height: 48px;
                    margin: 15px 0;
                }
                p:nth-of-type(2) {
                    font-size: 18px;
                }
                span {
                    font-size: 14px;
                }
            }
        }
    }
    div[experience] {
        background: #FFFFFF;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
        width: calc(100% - 160px);
        max-width: 1280px;
        margin: 0 auto;
        margin-top: -54px;
        position: relative;
        display: flex;
        flex-direction: column;
        @include breakpoint(small) {
            flex-direction: column;
            margin-top: -60px;
        }
        @include breakpoint(medium) {
            margin-top: -60px;
        }
        @include breakpoint(large) {
            width: calc(100% - 160px);
            max-width: 100%;
        }
        div[content] {
            text-align: center;
            padding: 35px;
            >p[title] {
                font-size: 16px;
                color: #00338D;
                letter-spacing: 0.67px;
                margin: 10px 0 0;
                text-transform: uppercase;
                font-weight: bold;
                position: relative;
                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    width: 55px;
                    border-top: solid 2px #00338D;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &::before {
                    margin-left: -85px;
                }
                &::after {
                    margin-left: 30px;
                }
            }
            >h3 {
                margin: 45px 0;
                font-size: 40px;
                color: #00338D;
                line-height: 58px;
            }
            p:nth-of-type(2) {
                font-size: 18px;
                color: #111111;
                line-height: 28px;
                font-weight: normal;
                margin: 0;
            }
            p:nth-of-type(3) {
                font-size: 18px;
                color: #111111;
                line-height: 28px;
                font-weight: normal;
                margin: 0 0 35px;
            }
            span {
                font-size: 20px;
                font-weight: bold;
                color: #00338D;
                text-align: center;
                line-height: 28px;
            }
            a[button] {
                background: #00338D;
                color: #FFF;
                padding: 6px 25px;
                font-size: 14px;
                text-decoration: none;
                letter-spacing: 0.25px;
            }
            @include breakpoint(medium) {
                >h3 {
                    font-size: 28px;
                    line-height: 46px;
                }
                p:nth-of-type(2) {
                    font-size: 14px;
                    color: #111111;
                    line-height: 24px;
                }
            }
        }
        div[info-block] {
            display: flex;
            margin-bottom: 45px;
            justify-content: center;
            >div {
                max-width: 275px;
                min-height: 275px;
                padding: 15px;
                border: 2px solid #00338D;
                border-bottom: 6px solid #00338D;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                margin: 0 15px 0 15px;
                box-sizing: border-box;
                span {
                    font-size: 18px;
                    color: #111111;
                    text-align: center;
                    line-height: 28px;
                    max-width: 225px;
                    b {
                        color: #00338D;
                    }
                }
                span+img {
                    cursor: pointer;
                }
            }
        }
        div[video] {
            flex: 0 55%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            @include breakpoint(small) {
                width: calc(100% - 100px);
                margin: 0 auto 38px;
            }
        }
    }
    div[policy-procedure] {
        background: #00338d;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 130px;
        margin-bottom: 200px;
        button {
            background-color: #00338D;
            color: white;
            margin: 18px 0px;
            padding: 6px 20px;
        }
        div[one] {
            width: 920px;
            background: #fff;
            margin-top: -65px;
            >div {
                &:first-child {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    >p[title] {
                        font-size: 16px;
                        color: #00338D;
                        letter-spacing: 0.67px;
                        margin: 0;
                        text-transform: uppercase;
                        font-weight: bold;
                        position: relative;
                        margin: 45px 0 0;
                        &::after,
                        &::before {
                            content: '';
                            position: absolute;
                            width: 55px;
                            border-top: solid 2px #00338D;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        &::before {
                            margin-left: -85px;
                        }
                        &::after {
                            margin-left: 30px;
                        }
                    }
                    >h3 {
                        margin: 45px 0;
                        font-size: 40px;
                        color: #00338D;
                        line-height: 58px;
                    }
                    span[s-video] {
                        display: inline-block;
                        font-size: 20px;
                        color: #111111;
                        text-align: center;
                        line-height: 32px;
                        margin: 0 35px 0;
                        padding: 0px 90px;
                        width: 920px;
                        box-sizing: border-box;
                    }
                    span[travel] {
                        display: inline-block;
                        font-weight: bold;
                        font-size: 18px;
                        color: #212D3A;
                        text-align: center;
                        line-height: 28px;
                        margin-top: 20px;
                    }
                }
            }
            div[video] {
                width: 820px;
                margin: 40px auto;
                margin-top: 20px;
                position: relative;
                display: block;
                background: #00338d;
                height: 535px;
                img {
                    &:first-child {
                        position: relative;
                        top: 50%;
                        left: 50%;
                        /*  width: 109px; */
                        opacity: 0.45;
                        width: 100%;
                        height: 100%;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        cursor: pointer;
                    }
                    &:last-child {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 109px;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        cursor: pointer;
                    }
                }
            }
        }
        div[two] {
            display: flex;
            margin-top: 100px;
            width: 100%;
            >div {
                &:first-child {
                    flex: 0 50%;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                &:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex: 0 50%;
                    >p[title] {
                        font-size: 16px;
                        color: #FFF;
                        letter-spacing: 0.67px;
                        margin: 0;
                        text-transform: uppercase;
                        font-weight: bold;
                        position: relative;
                        margin: 35px 0 0;
                        &::after,
                        &::before {
                            content: '';
                            position: absolute;
                            width: 55px;
                            border-top: solid 2px #FFF;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        &::before {
                            margin-left: -85px;
                        }
                        &::after {
                            margin-left: 30px;
                        }
                    }
                    >h3 {
                        font-size: 40px;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 58px;
                        font-weight: bold;
                        margin: 45px 0;
                    }
                    >span {
                        font-size: 18px;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 28px;
                    }
                    span[top] {
                        display: inline-block;
                        width: 85%;
                        margin-bottom: 25px;
                    }
                    span[bottom] {
                        display: inline-block;
                        width: 85%;
                    }
                }
            }
        }
        div[three] {
            display: flex;
            margin-top: 100px;
            width: 100%;
            >div {
                &:first-child {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex: 0 50%;
                    >p[title] {
                        font-size: 16px;
                        color: #FFF;
                        letter-spacing: 0.67px;
                        margin: 0;
                        text-transform: uppercase;
                        font-weight: bold;
                        position: relative;
                        margin: 35px 0 0;
                        &::after,
                        &::before {
                            content: '';
                            position: absolute;
                            width: 55px;
                            border-top: solid 2px #FFF;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        &::before {
                            margin-left: -85px;
                        }
                        &::after {
                            margin-left: 30px;
                        }
                    }
                    button {
                        background-color: white;
                        color: #00338D;                       
                    }
                    >h3 {
                        font-size: 40px;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 58px;
                        font-weight: bold;
                        margin: 45px 0;
                    }
                    >span {
                        font-size: 18px;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 28px;
                    }
                    span[top] {
                        display: inline-block;
                        width: 85%;
                        margin-bottom: 25px;
                    }
                    span[bottom] {
                        display: inline-block;
                        width: 85%;
                    }
                }
                &:nth-child(2) {
                    flex: 0 50%;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }                    
            }
        }
        div[template] {
            display: flex;
            margin-top: 100px;
            width: 940px;
            background: #fff;
            box-sizing: border-box;
            >div {
                &:first-child {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex: 0 60%;
                    >p[title] {
                        font-size: 16px;
                        color: #00338D;
                        letter-spacing: 0.67px;
                        margin: 0;
                        text-transform: uppercase;
                        font-weight: bold;
                        position: relative;
                        margin: 35px 0 0;
                        &::after,
                        &::before {
                            content: '';
                            position: absolute;
                            width: 55px;
                            border-top: solid 2px #FFF;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        &::before {
                            margin-left: -85px;
                        }
                        &::after {
                            margin-left: 30px;
                        }
                    }
                    >h3 {
                        font-size: 40px;
                        color: #00338D;
                        text-align: center;
                        line-height: 58px;
                        font-weight: bold;
                        margin: 35px 0;
                    }
                    >span {
                        font-size: 18px;
                        color: #FFFFFF;
                        text-align: center;
                        font-size: 20px;
                        color: #111111;
                        text-align: center;
                        line-height: 32px;
                    }
                    span[top] {
                        display: inline-block;
                        width: 85%;
                        margin-bottom: 20px;
                    }
                    span[bottom] {
                        display: inline-block;
                        width: 85%;
                    }
                }
                &:nth-child(2) {
                    flex: 0 40%;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }                    
            }
        }
        div[four] {
            width: 920px;
            margin-top: 100px;
            margin-bottom: -100px;
            background: #fff;
            box-sizing: border-box;
            >div {
                &:first-child {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    >p[title] {
                        font-size: 16px;
                        color: #00338D;
                        letter-spacing: 0.67px;
                        margin: 0;
                        text-transform: uppercase;
                        font-weight: bold;
                        position: relative;
                        margin: 45px 0 0;
                        &::after,
                        &::before {
                            content: '';
                            position: absolute;
                            width: 55px;
                            border-top: solid 2px #00338D;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        &::before {
                            margin-left: -85px;
                        }
                        &::after {
                            margin-left: 30px;
                        }
                    }
                    >h3 {
                        margin: 45px 0;
                        font-size: 40px;
                        color: #00338D;
                        line-height: 58px;
                    }
                    span {
                        display: inline-block;
                        font-size: 20px;
                        color: #111111;
                        text-align: center;
                        line-height: 32px;
                        margin: 0 35px 0;
                        border-bottom: 1px solid #ddd;
                        padding: 0px 90px 50px;
                        width: 920px;
                        box-sizing: border-box;
                    }
                }
                &:nth-child(2) {
                    display: flex;
                    margin-bottom: 15px;
                    >div {
                        &:first-child {
                            width: 30%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img {
                                margin: 65px;
                            }
                        }
                        &:nth-child(2) {
                            width: 70%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding-right: 65px;
                            >h3 {
                                font-size: 40px;
                                color: #00338D;
                                text-align: center;
                                line-height: 58px;
                                margin: 18px 0;
                            }
                            span {
                                font-size: 18px;
                                font-weight: bold;
                                color: #111111;
                                text-align: center;
                                line-height: 28px;
                                padding: 0 0px 9px 0;
                                width: 100%;
                            }
                            >div {
                                display: flex;
                            }
                        }
                    }
                }
            }
            button {
                margin: 18px 9px;
                display: inline-block;
                width: 150px;
            }
        }
    }
    div[about-lakehouse] {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 100px;
        >p[title] {
            font-size: 16px;
            color: #00338D;
            letter-spacing: 0.67px;
            margin: 0;
            text-transform: uppercase;
            font-weight: bold;
            position: relative;
            /* margin: 35px 0; */
            &::after,
            &::before {
                content: '';
                position: absolute;
                width: 55px;
                border-top: solid 2px #00338D;
                top: 50%;
                transform: translateY(-50%);
            }
            &::before {
                margin-left: -85px;
            }
            &::after {
                margin-left: 30px;
            }
        }
        >h3 {
            font-weight: bold;
            font-size: 40px;
            color: #00338D;
            text-align: center;
            line-height: 58px;
            margin: 45px 0 55px;
        }
        >div {
            display: flex;
            >div {
                display: flex;
                flex-direction: column;
                &:first-child {
                    >div {
                        &:first-child,
                        &:nth-child(2) {
                            display: flex;
                            align-items: center;
                            padding: 0 20px 0 20px;
                            margin: 0 15px 15px 0;
                            width: 450px;
                            height: 80px;
                            background: #FFFFFF;
                            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
                            >span {
                                flex: 1 1 auto;
                                font-size: 18px;
                                font-weight: bold;
                                color: #111111;
                                line-height: 26px;
                            }
                            >img {
                                &:first-child {
                                    margin-right: 20px;
                                    width: 32px;
                                }
                            }
                            >a {
                                img {
                                    margin-left: 20px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    @include breakpoint(small) {
                        >div {
                            &:first-child,
                            &:nth-child(2) {
                                width: 280px;
                            }
                        }
                    }
                }
                &:nth-child(2) {
                    >div {
                        &:first-child {
                            display: flex;
                            align-items: center;
                            padding: 0 20px 0 20px;
                            margin: 0 15px 15px 0;
                            width: 450px;
                            height: 80px;
                            background: #FFFFFF;
                            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
                            >span {
                                flex: 1 1 auto;
                                font-size: 18px;
                                font-weight: bold;
                                color: #111111;
                                line-height: 26px;
                            }
                            >img {
                                &:first-child {
                                    margin-right: 20px;
                                    width: 32px;
                                }
                                &:last-child {
                                    cursor: pointer;
                                }
                            }
                        }
                        &:nth-child(2) {
                            display: flex;
                            align-items: center;
                            padding: 0 20px 0 20px;
                            margin: 0 15px 15px 0;
                            width: 450px;
                            height: 80px;
                            background: #FFFFFF;
                            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
                            >img {
                                &:first-child {
                                    margin-right: 20px;
                                    width: 32px;
                                }
                                &:last-child {
                                    cursor: pointer;
                                }
                            }
                            >div {
                                display: flex;
                                flex-direction: column;
                                flex: 1 1 auto;
                                >span {
                                    &:first-child {
                                        font-size: 18px;
                                        font-weight: bold;
                                        color: #111111;
                                        line-height: 26px;
                                    }
                                    &:nth-child(2) {
                                        font-size: 16px;
                                        color: #111111;
                                        line-height: 26px;
                                    }
                                }
                            }
                        }
                    }
                    @include breakpoint(small) {
                        >div {
                            &:first-child,
                            &:nth-child(2) {
                                width: 280px;
                            }
                        }
                    }
                }
            }
        }
    }
    div[app] {
        margin-top: 95px;
        text-align: center;
        >p[title] {
            font-size: 16px;
            color: #00338D;
            letter-spacing: 0.67px;
            margin: 0;
            text-transform: uppercase;
            font-weight: bold;
            position: relative;
            &::before,
            &::after {
                content: '';
                position: absolute;
                width: 55px;
                border-top: solid 2px #00338D;
                top: 50%;
                transform: translateY(-50%);
            }
            &::before {
                margin-left: -85px;
            }
            &::after {
                margin-left: 30px;
            }
        }
        >h3 {
            font-size: 40px;
            color: #00338D;
            line-height: 58px;
            margin: 35px 0 25px;
        }
        >p[desc] {
            font-size: 18px;
            color: #111111;
            line-height: 28px;
            width: 500px;
            margin: 0 auto;
        }
        >div[appbutton] {
            margin: 35px 0 60px;
            >img:first-child {
                margin-right: 15px;
            }
        }
        >img {
            margin-bottom: 70px;
        }
    }
}

journey-courses {
    max-height: calc(100vh - 260px);
    overflow: auto;
}

journey-courses,
journey-flight,
journey-stay {
    display: block;
    max-width: 1280px;
    min-width: 1024px;
    margin: auto;
    margin-top: 18px;
    margin-bottom: 75px;
    background: #FFF;
    border-top: 3px solid #00338D;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
    div[course-head],
    div[flight-head],
    div[stay-head] {
        display: flex;
        align-items: center;
        border-bottom: 1.5px solid #E5E5E5;
        height: 72px;
        box-sizing: border-box;
        padding: 0 20px 0 25px;
        span:nth-child(2) {
            font-size: 20px;
            color: #111111;
            font-weight: bold;
            flex: 1 1 auto;
            margin-left: 10px;
        }
        button {
            padding: 10px 20px;
            border: 0;
            background: #00338D;
            font-size: 18px;
            color: #FFFFFF;
            letter-spacing: 0.32px;
            cursor: pointer;
            font-weight: normal;
        }
        img {
            margin-right: 10px;
        }
    }
    div[stay-head]+div,
    div[flight-head]+div,
    div[course-head]+div {
        padding: 10px 25px;
        border-bottom: 1.5px solid #E5E5E5;
        background: #F9F9F9;
        span {
            p {
                margin: 0;
                a {
                    font-size: 16px;
                    font-weight: bold;
                    color: #00338D;
                    letter-spacing: 0;
                    text-decoration: none;
                    line-height: 18px;
                }
            }
        }
    }
    div[note] {
        padding: 10px;
        border-bottom: 1.5px solid #E5E5E5;
        span:first-child {
            font-size: 16px;
            color: #111111;
            letter-spacing: 0;
            text-align: left;
            line-height: 18px;
            font-weight: bold;
        }
        span:nth-child(2) {
            font-size: 16px;
            color: #111111;
            letter-spacing: 0;
            text-align: left;
            line-height: 18px;
            a {
                font-size: 16px;
                color: #00338D;
                letter-spacing: 0;
                line-height: 18px;
                font-weight: bold;
                text-decoration: none;
            }
        }
    }
    div[tips] {
        display: flex;
        flex-direction: column;
        padding: 25px 20px 0 25px;
        box-sizing: border-box;
        border-bottom: 1.5px solid #E5E5E5;
        >div {
            &:first-child {
                display: flex;
                align-items: center;
                margin-bottom: 25px;
                >span {
                    flex: 1 1 auto;
                    margin-left: 10px;
                    font-size: 25px;
                    color: rgba(0, 0, 0, 0.87);
                    letter-spacing: 0;
                    font-weight: bold;
                }
                >img {
                    cursor: pointer;
                    &.active {
                        transform: rotate(180deg);
                    }
                }
            }
            &:last-child {
                margin-bottom: 25px;
                >p {
                    font-size: 16px;
                    color: #111111;
                    letter-spacing: 0;
                    text-align: left;
                    line-height: 22px;
                    padding: 0;
                    margin: 0;
                    &:first-child,
                    &:nth-child(3) {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
    div[register-course],
    div[register-flight],
    div[register-stay] {
        margin: 20px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        span:nth-child(2) {
            font-size: 40px;
            line-height: 58px;
            color: #00338D;
            text-align: center;
            font-weight: bold;
            margin: 35px 0;
            border-bottom: 2px solid #00338D;
        }
        span:nth-child(3),
        span:nth-child(7) {
            font-size: 18px;
            color: #111111;
            text-align: center;
            line-height: 28px;
            margin: 0 10px 20px;
            max-width: 100%;
            a {
                color: #00338D;
                letter-spacing: 0;
                line-height: 18px;
                font-weight: bold;
                text-decoration: none;
                cursor: pointer;
            }
        }
        span:nth-child(4),
        span:nth-child(6) {
            margin: 0;
            font-size: 18px;
            color: #111111;
            text-align: center;
            line-height: 28px;
        }
        span:nth-child(5) {
            margin-top: 20px;
            font-size: 18px;
            color: #111111;
            text-align: center;
            line-height: 28px;
        }
        section {
            span+a {
                font-size: 18px;
                color: #00338D;
                text-align: center;
                line-height: 28px;
            }
            >span {
                &:last-child {
                    font-size: 18px;
                    color: #00338D;
                    text-align: center;
                    line-height: 28px;
                }
            }
        }
        button {
            background: #00338D;
            color: #FFF;
            padding: 5px 10px;
            cursor: pointer;
            border: 0;
            font-size: 14px;
        }
    }
}

.amplify-form-button {
    display: block;
}

journey-courses {
    div[course-container] {
        display: flex;
        padding: 10px;
        div[course-list] {
            border-right: 2px solid #ddd;
            padding: 10px;
            max-width: 33%;
            min-width: 33%;
            overflow: auto;
            h3 {
                font-size: 25px;
                color: rgba(0, 0, 0, 0.87);
                padding: 10px;
                margin: 0 0 5px 5px;
            }
            div[card] {
                display: flex;
                background: #EFEFEF;
                padding: 10px;
                margin-bottom: 10px;
                cursor: pointer;
                color: #999999;
                min-height: 50px;
                >div {
                    &:first-child {
                        margin-right: 10px;
                        span {
                            i {
                                font-size: 30px;
                                &.active {
                                    color: #00338D;
                                }
                            }
                        }
                    }
                    &:nth-child(2) {
                        display: flex;
                        flex-direction: column;
                        span:first-child {
                            font-size: 20px;
                            font-weight: bold;
                            margin-bottom: 5px;
                            display: block;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            margin-right: 25px;
                            max-width: 278px;
                        }
                        span:nth-child(2) {
                            font-size: 16px;
                            line-height: 18px;
                        }
                    }
                }
                &.active {
                    color: #000;
                }
                &.selected {
                    background: #00338D;
                    color: #ffffff;
                    i.active {
                        color: #ffffff !important;
                    }
                }
            }
        }
        div[course-list-details] {
            display: flex;
            flex-direction: column;
            max-width: 67%;
            overflow: auto;
            h4 {
                font-size: 18px;
                color: #111111;
                font-weight: bold;
                margin: 15px;
            }
            div[upper] {
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
                padding: 20px;
                h1 {
                    font-size: 32px;
                    color: #00338D;
                    padding: 10px;
                    margin: 0 0 5px 5px;
                    background: #FFFFFF;
                }
                >div {
                    &:nth-child(2) {
                        background: #F6F6F6;
                        display: flex;
                        flex-wrap: wrap;
                        padding: 15px 15px 0;
                        max-width: 520px;
                        justify-content: flex-start;
                    }
                    &:nth-child(4) {
                        display: flex;
                        flex-wrap: wrap;
                        padding: 0 15px 0;
                        width: 520px;
                        justify-content: flex-start;
                        line-height: 22px;
                        span {
                            font-size: 16px;
                            color: #656565;
                            &:first-child {
                                text-transform: uppercase;
                            }
                        }
                        &.hide {
                            max-height: 60px;
                            overflow: hidden;
                        }
                    }
                    &:last-child {
                        display: flex;
                        padding: 0 15px 0;
                        margin-top: 10px;
                        font-size: 14px;
                        color: #00338D;
                        letter-spacing: 0;
                        cursor: pointer;
                    }
                    >div {
                        display: flex;
                        align-items: center;
                        margin: 0 20px 10px 0;
                        span:first-child {
                            i {
                                color: #00338D;
                                margin-right: 5px;
                            }
                        }
                        span:nth-child(2) {
                            font-size: 16px;
                            color: #111111;
                            margin-left: 15px;
                        }
                    }
                }
            }
            div[course-material] {
                div[material-card] {
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
                    padding: 10px 20px 5px 20px;
                    display: flex;
                    flex-direction: column;
                    span[title] {
                        font-size: 16px;
                        color: #111111;
                        margin: 15px;
                    }
                    div[a] {
                        span {
                            margin-left: 15px;
                            font-size: 16px;
                            color: #111111;
                            letter-spacing: 0;
                        }
                    }
                    div[b] {
                        display: flex;
                        align-items: center;
                        span:first-child {
                            flex: 1;
                            font-size: 14px;
                            color: #656565;
                            letter-spacing: 0;
                            margin-left: 15px;
                            display: flex;
                            align-items: center;
                            i {
                                margin-right: 5px;
                            }
                        }
                        span:nth-child(2) {
                            i {
                                color: #f5a829;
                            }
                        }
                    }
                }
            }
            div[assignments] {
                div[assignment-card] {
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
                    padding: 10px 20px 5px 20px;
                    display: flex;
                    flex-direction: column;
                    span[title] {
                        font-size: 16px;
                        color: #111111;
                        margin: 15px;
                    }
                    div[a] {
                        span {
                            margin-left: 15px;
                            font-size: 16px;
                            color: #111111;
                            letter-spacing: 0;
                        }
                    }
                    div[b] {
                        display: flex;
                        align-items: center;
                        span:first-child {
                            flex: 1;
                            font-size: 14px;
                            color: #656565;
                            letter-spacing: 0;
                            margin-left: 15px;
                            display: flex;
                            align-items: center;
                            i {
                                margin-right: 5px;
                            }
                        }
                        span:nth-child(2) {
                            i {
                                color: #f5a829;
                            }
                        }
                    }
                }
            }
        }
    }
}

journey-flight {
    div[flight-container] {
        display: flex;
        div[left],
        div[right] {
            flex: 1;
            padding: 35px 30px 35px 35px;
            h3 {
                font-size: 32px;
                color: #00338D;
                margin-bottom: 20px;
                margin-top: 0;
            }
            h4 {
                font-size: 18px;
                color: #111111;
                margin: 30px 0 15px 0;
            }
            div[carrier] {
                >div {
                    &:first-child {
                        font-size: 14px;
                        color: #656565;
                        letter-spacing: 0;
                        margin: 0 0 10px 0;
                    }
                    &:last-child {
                        font-size: 16px;
                        color: #111111;
                        font-weight: bold;
                        margin: 0 0 20px 0;
                    }
                }
            }
            div[flight] {
                display: flex;
                justify-content: space-between;
                padding: 15px;
                background: #F5F5F5;
                >div {
                    display: flex;
                    &:first-child {
                        width: 33%;
                        justify-content: space-around;
                        align-items: center;
                        >div {
                            display: flex;
                            flex-direction: column;
                            span:first-child {
                                font-size: 14px;
                                color: #656565;
                            }
                            span:nth-child(2) {
                                font-size: 25px;
                                color: #00338D;
                                font-weight: bold;
                                margin: 5px 0;
                            }
                            span:nth-child(3) {
                                font-size: 16px;
                                color: #111111;
                            }
                        }
                        >span {
                            display: flex;
                            border-radius: 50%;
                            border: 2px solid #00338D;
                            width: 40px;
                            height: 40px;
                            align-items: center;
                            justify-content: center;
                            margin: 5px 0;
                        }
                    }
                    &:nth-child(2) {
                        flex-direction: column;
                        margin: 5px 0;
                        justify-content: space-between;
                        >span {
                            font-size: 14px;
                            color: #656565;
                        }
                    }
                    &:nth-child(3) {
                        width: 33%;
                        justify-content: space-around;
                        align-items: center;
                        >span {
                            display: flex;
                            border-radius: 50%;
                            border: 2px solid #00338D;
                            width: 40px;
                            height: 40px;
                            align-items: center;
                            justify-content: center;
                            margin: 5px 0;
                        }
                        >div {
                            display: flex;
                            flex-direction: column;
                            span:first-child {
                                font-size: 14px;
                                color: #656565;
                            }
                            span:nth-child(2) {
                                font-size: 25px;
                                color: #00338D;
                                font-weight: bold;
                                margin: 5px 0;
                            }
                            span:nth-child(3) {
                                font-size: 16px;
                                color: #111111;
                            }
                        }
                    }
                }
            }
            div[flight-ref] {
                display: flex;
                box-sizing: border-box;
                >div {
                    display: flex;
                    flex-direction: column;
                    &:first-child {
                        flex: 0 13%;
                        margin-right: 30px;
                        span:first-child {
                            font-size: 14px;
                            color: #656565;
                        }
                        span:nth-child(2) {
                            font-size: 16px;
                            color: #111111;
                            font-weight: bold;
                            margin: 10px 0;
                        }
                    }
                    &:nth-child(2) {
                        flex: 0 35%;
                        margin-right: 30px;
                        span {
                            font-size: 14px;
                            color: #656565;
                            &:nth-child(2) {
                                font-size: 16px;
                                color: #111111;
                                font-weight: bold;
                                margin: 10px 0;
                            }
                        }
                    }
                    &:nth-child(3) {
                        flex: 0 35%;
                        margin-right: 30px;
                        span {
                            font-size: 14px;
                            color: #656565;
                            &:nth-child(2) {
                                margin: 10px 0;
                            }
                        }
                    }
                    &:nth-child(4) {
                        flex: 0 12%;
                        margin-right: 0;
                        span {
                            font-size: 14px;
                            color: #111111;
                            &:nth-child(2) {
                                margin: 10px 0;
                            }
                        }
                    }
                }
            }
            div[flight-detail] {
                display: flex;
                align-items: center;
                >div {
                    &:first-child {
                        display: flex;
                        align-items: center;
                    }
                }
                span {
                    i {
                        font-size: 12px;
                        color: #CCCCCC;
                        margin: 0 3px;
                    }
                    &:nth-child(2) {
                        i {
                            font-size: 40px;
                            color: #00338D;
                        }
                    }
                }
            }
        }
        div[left] {
            border-right: 1.5px solid #ddd;
        }
        i {
            color: #00338D;
        }
    }
    /************************ Media Query ******************************************/
    /*
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
    */
    @media (min-width: 768px) and (max-width: 1024px) {
        div[flight-container] {
            flex-direction: column;
        }
        div[left] {
            border-right: 0 !important;
            border-bottom: 1.5px solid #ddd;
        }
    }
    /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
    */
    @media (min-width: 1025px) and (max-width: 1200px) {
        h3 {
            font-size: 28px !important;
        }
        div[flight]>div:first-child>div span:nth-child(2),
        div[flight]>div:nth-child(3)>div span:nth-child(2) {
            font-size: 20px !important;
        }
        div[flight-detail]>div:first-child {
            span {
                img {
                    max-width: 100%;
                    height: auto;
                }
                i {
                    margin: 0 2px !important;
                }
            }
        }
    }
}

journey-stay {
    background: #FFF;
    overflow: hidden;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
    max-height: 550px;
    overflow: auto;
    div[room-head] {
        display: flex;
        align-items: center;
        border-bottom: 1.5px solid #E5E5E5;
        height: 72px;
        box-sizing: border-box;
        padding: 0 25px;
        span:nth-child(2) {
            font-size: 20px;
            color: #111111;
            font-weight: bold;
            flex: 1 1 auto;
        }
        button {
            font-size: 18px;
            color: #FFFFFF;
            letter-spacing: 0.32px;
            background: #00338D;
            font-weight: normal;
            padding: 10px 20px;
        }
        img {
            margin-right: 20px;
        }
    }
    div[note] {
        padding: 10px 25px;
        border-bottom: 1.5px solid #E5E5E5;
        background: #F9F9F9;
        span:first-child {
            font-size: 16px;
            color: #111111;
            letter-spacing: 0;
            text-align: left;
            line-height: 18px;
            font-weight: bold;
        }
        span:nth-child(2) {
            font-size: 16px;
            color: #111111;
            letter-spacing: 0;
            text-align: left;
            line-height: 18px;
        }
    }
    div[journey-stay] {
        display: flex;
        flex-direction: column;
        div[room-container] {
            display: flex;
            div[left] {
                flex: none;
                padding: 25px;
                margin: auto;
                h3 {
                    font-size: 32px;
                    color: #00338D;
                    margin-bottom: 20px;
                }
                h4 {
                    font-size: 18px;
                    color: #111111;
                    margin: 0 10px 10px 0;
                    span {
                        color: #00338D;
                        &:first-child {
                            margin: 0 5px;
                        }
                    }
                }
                div[room] {
                    display: flex;
                    justify-content: space-between;
                    padding: 15px;
                    background: #F5F5F5;
                    margin-bottom: 30px;
                    min-width: 350px;
                    >div {
                        display: flex;
                        &:first-child {
                            width: 33%;
                            justify-content: space-around;
                            align-items: center;
                            >div {
                                display: flex;
                                flex-direction: column;
                                span:first-child {
                                    font-size: 14px;
                                    color: #656565;
                                }
                                span:nth-child(2) {
                                    font-size: 25px;
                                    color: #00338D;
                                    font-weight: bold;
                                    margin: 5px 0;
                                }
                                span:nth-child(3) {
                                    font-size: 16px;
                                    color: #111111;
                                }
                            }
                            >span {
                                display: flex;
                                border-radius: 50%;
                                border: 2px solid #00338D;
                                width: 40px;
                                height: 40px;
                                align-items: center;
                                justify-content: center;
                                margin: 5px 0;
                            }
                        }
                        &:nth-child(2) {
                            flex-direction: column;
                            margin: 5px 0;
                            justify-content: space-between;
                            >span {
                                font-size: 14px;
                                color: #656565;
                            }
                        }
                        &:nth-child(3) {
                            width: 33%;
                            justify-content: space-around;
                            align-items: center;
                            >span {
                                display: flex;
                                border-radius: 50%;
                                border: 2px solid #00338D;
                                width: 40px;
                                height: 40px;
                                align-items: center;
                                justify-content: center;
                                margin: 5px 0;
                            }
                            >div {
                                display: flex;
                                flex-direction: column;
                                span:first-child {
                                    font-size: 14px;
                                    color: #656565;
                                }
                                span:nth-child(2) {
                                    font-size: 25px;
                                    color: #00338D;
                                    font-weight: bold;
                                    margin: 5px 0;
                                }
                                span:nth-child(3) {
                                    font-size: 16px;
                                    color: #111111;
                                }
                            }
                        }
                    }
                }
                div[room-ref] {
                    display: flex;
                    margin-bottom: 15px;
                    >div {
                        display: flex;
                        flex-direction: column;
                        &:first-child {
                            flex: 1;
                            span:first-child {
                                font-size: 14px;
                                color: #656565;
                            }
                            span:nth-child(2) {
                                font-size: 16px;
                                color: #111111;
                                font-weight: bold;
                                margin: 10px 0;
                            }
                        }
                        &:nth-child(2) {
                            flex: 1;
                            span {
                                font-size: 14px;
                                color: #656565;
                                &:nth-child(2) {
                                    font-size: 16px;
                                    color: #111111;
                                    font-weight: bold;
                                    margin: 10px 0;
                                }
                            }
                        }
                        &:nth-child(3) {
                            flex: 2;
                            span {
                                font-size: 14px;
                                color: #656565;
                                &:nth-child(2) {
                                    margin: 10px 0;
                                }
                            }
                        }
                        &:nth-child(4) {
                            flex: 2;
                            span {
                                font-size: 14px;
                                color: #111111;
                                &:nth-child(2) {
                                    margin: 10px 0;
                                }
                            }
                        }
                    }
                }
                div[room-ref]+h4+div {
                    ul {
                        padding-left: 15px;
                        li {
                            span {
                                color: #00338D;
                                font-weight: bold;
                                cursor: pointer;
                            }
                        }
                    }
                }
                div[room-detail] {
                    display: flex;
                    align-items: center;
                    >div {
                        &:first-child {
                            display: flex;
                            align-items: center;
                            span {
                                img {
                                    margin: 0 4px;
                                }
                            }
                        }
                    }
                    span {
                        i {
                            font-size: 12px;
                            color: #CCCCCC;
                        }
                        &:nth-child(2) {
                            i {
                                font-size: 40px;
                                color: #00338D;
                            }
                        }
                    }
                }
            }
            div[right] {
                img {
                    width: 100%;
                    height: auto;
                }
                flex: 2;
                padding: 30px;
                h3 {
                    font-size: 32px;
                    color: #00338D;
                    margin-bottom: 20px;
                }
                h4 {
                    font-size: 18px;
                    color: #111111;
                    margin: 15px 0;
                }
                div[room] {
                    display: flex;
                    justify-content: space-between;
                    padding: 15px;
                    background: #F5F5F5;
                    >div {
                        display: flex;
                        &:first-child {
                            width: 33%;
                            justify-content: space-around;
                            align-items: center;
                            >div {
                                display: flex;
                                flex-direction: column;
                                span:first-child {
                                    font-size: 14px;
                                    color: #656565;
                                }
                                span:nth-child(2) {
                                    font-size: 25px;
                                    color: #00338D;
                                    font-weight: bold;
                                    margin: 5px 0;
                                }
                                span:nth-child(3) {
                                    font-size: 16px;
                                    color: #111111;
                                }
                            }
                            >span {
                                display: flex;
                                border-radius: 50%;
                                border: 2px solid #00338D;
                                width: 40px;
                                height: 40px;
                                align-items: center;
                                justify-content: center;
                                margin: 5px 0;
                            }
                        }
                        &:nth-child(2) {
                            flex-direction: column;
                            margin: 5px 0;
                            justify-content: space-between;
                            >span {
                                font-size: 14px;
                                color: #656565;
                            }
                        }
                        &:nth-child(3) {
                            width: 33%;
                            justify-content: space-around;
                            align-items: center;
                            >span {
                                display: flex;
                                border-radius: 50%;
                                border: 2px solid #00338D;
                                width: 40px;
                                height: 40px;
                                align-items: center;
                                justify-content: center;
                                margin: 5px 0;
                            }
                            >div {
                                display: flex;
                                flex-direction: column;
                                span:first-child {
                                    font-size: 14px;
                                    color: #656565;
                                }
                                span:nth-child(2) {
                                    font-size: 25px;
                                    color: #00338D;
                                    font-weight: bold;
                                    margin: 5px 0;
                                }
                                span:nth-child(3) {
                                    font-size: 16px;
                                    color: #111111;
                                }
                            }
                        }
                    }
                }
                div[room-ref] {
                    display: flex;
                    >div {
                        display: flex;
                        flex-direction: column;
                        &:first-child {
                            flex: 1;
                            span:first-child {
                                font-size: 14px;
                                color: #656565;
                            }
                            span:nth-child(2) {
                                font-size: 16px;
                                color: #111111;
                                font-weight: bold;
                                margin: 10px 0;
                            }
                        }
                        &:nth-child(2) {
                            flex: 1;
                            span {
                                font-size: 14px;
                                color: #656565;
                                &:nth-child(2) {
                                    font-size: 16px;
                                    color: #111111;
                                    font-weight: bold;
                                    margin: 10px 0;
                                }
                            }
                        }
                        &:nth-child(3) {
                            flex: 2;
                            span {
                                font-size: 14px;
                                color: #656565;
                                &:nth-child(2) {
                                    margin: 10px 0;
                                }
                            }
                        }
                        &:nth-child(4) {
                            flex: 2;
                            span {
                                font-size: 14px;
                                color: #111111;
                                &:nth-child(2) {
                                    margin: 10px 0;
                                }
                            }
                        }
                    }
                }
                div[room-detail] {
                    display: flex;
                    align-items: center;
                    >div {
                        &:first-child {
                            display: flex;
                            align-items: center;
                        }
                    }
                    span {
                        i {
                            font-size: 12px;
                            color: #CCCCCC;
                            margin: 0 3px;
                        }
                    }
                }
            }
        }
    }
}
.journey-home-scroll-init {
    height: calc(100vh - 530px) !important;
    overflow-y: auto;
}
.journey-home-scroll {
    height:calc(100vh - 230px) !important;
    overflow-y: auto;
}
