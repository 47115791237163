@import '@aws-amplify/ui/dist/styles.css';


html {
  font-family: var(--amplify-fonts-default-static);
}

@supports (font-variation-settings: normal) {
  html {
    font-family: var(--amplify-fonts-default-variable);
  }
}

.federated-sign-in-container {
  flex-direction: column;
  padding: 0 0 1rem 0;
}

.federated-sign-in-button {
  color: brown;
  display: block;
}

.federated-sign-in-button-row {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


[data-amplify-authenticator] [data-amplify-form] {
    background-color: #002e7f;
}

[data-amplify-authenticator] [data-amplify-footer] {
    background-color: #002e7f;
    padding-bottom: 5px;
}

.amplify-label {
    color: white;
}

.amplify-button--fullwidth {
    width: 100%;
    background-color: #002e7f;
    text-transform: uppercase;
    font-size: small;
}

.amplify-button--small {
    text-transform: capitalize;
}
.amplify-button--small:hover {
    text-decoration: underline;
    color: gray;
}

.amplify-input {
    color: white;
}

.amplify-heading {
    color: white;
}

.amplify-text--error {
    color: red;
}

.amplify-button--link {
    color: gray;
}

.amplify-button:focus {
    color: black;
}