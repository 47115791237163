side-nav {
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    >div {
        border-right: 1px solid #E5E5E5;
        background: white;
        width: 213px;
        height: 100vh;
        box-sizing: border-box;
        &:nth-child(1) {
            z-index: 99;
            >.title {
                background-color: #fff;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 15px;
                border-bottom: 1px solid #E5E5E5;
                >img {
                    width: 65px;
                    transition: width 0.3s linear;
                }
                >div {
                    >img {
                        width: 37px;
                        height: 15px;
                    }
                    >span {
                        display: block;
                        font-size: 13px;
                        color: white;
                        text-transform: uppercase;
                        letter-spacing: 3px;
                    }
                }
                >i {
                    color: #00338D;
                    font-size: 14px;
                    position: relative;
                    cursor: pointer;
                    transition: transform .8s ease-in-out;
                    -moz-transform: rotateX(180deg) scaleX(-1);
                    -ms-transform: rotateX(180deg) scaleX(-1);
                    -webkit-transform: rotateX(180deg) scaleX(-1);
                    transform: rotateX(180deg) scaleX(-1);
                }
            }
            &[collapsed="true"] {
                width: 80px;
                >.title {
                    padding: 0 5px;
                    >img {
                        width: 46px;
                    }
                    >div {
                        display: none;
                    }
                    >i {
                        transform: none;
                    }
                }
                >div {
                    a {
                        >span,
                        >i {
                            display: none;
                        }
                    }
                }
                +div[collapsed="false"] {
                    left: 80px;
                }
            }
            @media screen and (max-width:1024px) {
                width: 80px !important;
                >.title {
                    >div {
                        display: none;
                    }
                }
                >div {
                    a {
                        >span {
                            display: none;
                        }
                    }
                }
                +div[collapsed="false"] {
                    left: 80px;
                }
            }
        }
        &:nth-child(2) {
            transition: left .8s ease-in-out;
            position: absolute;
            left: -300px;
            width: 300px;
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.16), 0 0 10px 0 rgba(87, 43, 43, 0.12);
            z-index: 9;
            >.title {
                height: 70px;
                border-bottom: 1px solid #E5E5E5;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 25px;
                button[sidenav-close] {
                    padding: 10px;
                    background: transparent;
                    &:hover {
                        background: #f8f8f8;
                    }
                    >i {
                        margin: 0;
                    }
                }
                >label {
                    font-family: Arial;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 21px;
                }
            }
            &[collapsed="false"] {
                display: block;
                left: 213px;
            }
        }
    }
    ul {
        list-style: none;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
    }
    div[main] {
        li {
            position: relative;
            >a {
                color: #111111;
                font-family: arial;
                font-size: 16px;
                font-weight: bold;
                text-decoration: none;
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 20px 0 20px 25px;
                &:hover {
                    background: rgba(0, 51, 141, 0.04);
                }
                &.active {
                    background: #00338D;
                    color: #ffffff;
                    >i {
                        color: #ffffff;
                        // transform: rotateX(180deg) scaleX(-1);
                        &.active {
                            transform: rotateX(180deg) scaleX(-1);
                        }
                    }
                }
                >i {
                    position: absolute;
                    float: right;
                    top: calc(50% - 12px);
                    right: 13px;
                    color: #00338D;
                    &.active {
                        transform: rotateX(180deg) scaleX(-1);
                    }
                }
                >img {
                    margin-right: 15px;
                    width: 24px;
                }
            }
        }
    }
    div[sub] {
        padding-left: 25px;
        div[eventTitle] {
            >span {
                padding: 30px 0 0;
                display: block;
                text-transform: uppercase;
                color: #888888;
                font-weight: bold;
                font-size: 14px;
            }
        }
        li {
            border-bottom: 1px solid #ddd;
            padding: 20px 0;
            >span {
                color: #888888;
                display: block;
                margin-top: 10px;
            }
            >a {
                color: #00338D;
                font-family: arial;
                font-size: 16px;
                font-weight: bold;
                text-decoration: none;
                display: flex;
                align-items: center;
                cursor: pointer;
                &:hover {
                    color: #005EB8;
                }
                >span {
                    max-width: 212px;
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    ~a {
                        transition: all .8s ease-in-out;
                        font-size: 0;
                        opacity: 0;
                    }
                    &.active {
                        font-weight: bold;
                        font-size: 16px;
                        display: inline-block;
                        padding-bottom: 5px;
                        color: #00338D;
                        ~a {
                            padding: 10px 0px;
                            font-size: 16px;
                            opacity: 1;
                            &:hover {
                                opacity: 0.5;
                            }
                        }
                        +i {
                            transform: rotateX(180deg) scaleX(-1);
                        }
                    }
                }
            }
            &:hover {
                opacity: 1;
            }
            >i {
                position: relative;
                top: -3px;
                right: 7px;
                float: right;
                transition: transform .8s ease-in-out;
                +span {
                    color: #888888;
                    font-size: 14px;
                    display: block;
                }
            }
        }
        a {
            color: #00338D;
        }
    }
}

tabs {
    border-top: 1px solid #ddd;
    background-color: #fff;
    >div {
        display: flex;
        align-items: center;
        background-color: #fff;
        border-bottom: 1px solid #ddd;
        >mat-tab-group {
            flex: 1;
            .mat-tab-header {
                border-bottom: none;
            }
        }
        >div:first-child {
            border: 1px solid #ddd;
            padding: 13px;
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .custom-ts {
                width: 0;
                padding-right: 12px;
            }
        }
        >div:last-child {
            width: 65px;
            >span {
                &:nth-child(3) {
                    display: none;
                }
            }
        }
    }
    div[toggle] {
        padding-top: 9px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #fff;
        border-left: 1px solid #ddd;
        span {
            margin-right: 2px;
            &:first-child {
                cursor: pointer;
            }
            &:nth-child(2) {
                position: relative;
                top: 2px;
            }
            &:nth-child(4) {
                flex: 1 1 auto;
            }
            &:nth-child(3) {
                font-size: 17px;
                font-weight: bold;
                margin-left: 10px;
            }
            i {
                color: #00338D;
                &.active {
                    transform: rotate(180deg) scale(1);
                    transition: transform .8s ease-in-out;
                }
            }
        }
    }
    ad-hoc {
        form {
            +div {
                display: none;
                width: 50%;
            }
        }
    }
    &.open {
        >div {
            >div {
                width: 50%;
                >span {
                    &:nth-child(3) {
                        display: inline-block;
                    }
                }
            }
        }
        ad-hoc {
            form {
                +div {
                    display: block;
                }
            }
        }
    }
}

div[breadcrumbs] {
    $Breadcrumb-color: #888888;
    $Breadcrumb-separator: '>';
    $Breadcrumb-min-width: 80px;
    $Breadcrumb-padding: 0 5px;
    background-color: #fff;
    position: absolute;
    top: 0px;
    .Breadcrumb {
        margin: 0;
        display: flex;
        align-items: center;
        min-width: 0; // this allows items to shrink less than initial size
        padding: 17px;
        list-style: none;
    }
    li.active ::after {
        content: " ";
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 18px;
        vertical-align: middle;
        border-top: 6px solid;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        cursor: pointer;
        color: #00338D;
    }
    .Breadcrumb-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // min-width: $Breadcrumb-min-width; // this allows items to shrink less than initial size
        a.Breadcrumb-link {
            color: red;
            font-size: 14px;
            text-decoration: none;
            color: #888888;
            &.active {
                color: #00338D;
                font-weight: bold;
            }
        }
        +li:before {
            // content: "#{$Breadcrumb-separator}";
            content: url('../assets/icons/breadcrumb-arrow.svg');
            // padding: $Breadcrumb-padding;
            margin: 0 5px 0 10px;
            color: $Breadcrumb-color;
        }
        i {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 18px;
            vertical-align: middle;
            border-top: 6px solid;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
            cursor: pointer;
            color: #00338D;
        }
    }
    li:not(.Breadcrumb-item) {
        &:before {
            display: none;
        }
        margin-left: 9px;
    }
}

time-picker {
    .time-picker {
        background: #fff;
        border: solid 1px #e5e5e5;
        z-index: 9;
        width: 250px;
        height: 200px;
        background: #fff;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        z-index: 9;
        display: flex;
        justify-content: space-around;
        &[open="false"] {
            display: none;
        }
        .t-row {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span {
                color: #888888;
                text-transform: uppercase;
            }
            i {
                font-size: 40px;
                color: #888888;
            }
            input {
                width: 50px;
                height: 25px;
                text-align: center;
            }
        }
    }
}

admin {
    div.page {
        display: flex;
        flex-direction: row;
        >.center {
            position: relative;
            flex: 1;
            margin-bottom: 60px;
            div[header] {
                margin-bottom: 20px;
                display: flex;
                border-bottom: 1px solid #ddd;
                background-color: #FFFFFF;
                height: 60px;
                padding-left: 15px;
                padding-right: 15px;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                height: 60px;
                div.user-group {
                    display: flex;
                    align-items: center;
                    position: relative;
                    span {
                        >i {
                            font-size: 30px;
                            color: #00338D;
                        }
                    }
                     :nth-child(2) {
                        position: relative;
                        margin-left: 10px;
                    }
                     :nth-child(3) {
                        margin-left: 15px;
                        margin-right: 10px;
                    }
                }
                .spacer {
                    flex: 1 1 auto;
                }
                .caret {
                    display: inline-block;
                    width: 0;
                    height: 0;
                    margin-left: 18px;
                    vertical-align: middle;
                    border-top: 6px solid;
                    border-right: 6px solid transparent;
                    border-left: 6px solid transparent;
                    cursor: pointer;
                    color: #00338D;
                }
                .x {
                    position: relative;
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    border: 2px solid #00338D;
                    display: flex;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                }
                .y {
                    position: absolute;
                    top: 6px;
                    left: 5px;
                    color: #00338D;
                    font-size: 13px;
                }
            }
            >router-outlet {
                +* {
                    display: block;
                    height: calc(100vh - 60px);
                    overflow: auto;
                    margin-top: 60px;
                }
            }
        }
    }
}

dashboard {
    background-color: #eee;
    div[summary] {
        display: flex;
        flex-direction: row;
        padding: 20px;
        margin: 20px;
        height: 200px;
        /* border: 1px solid #ddd;
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;*/
        background-color: rgba(0, 51, 141, 1);
        background-image: url('../assets/images/banner.png');
        position: relative;
        >.overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 200px;
            background: rgba(0, 51, 141, 0.8);
            display: flex;
            flex-direction: row;
            padding: 20px;
            border: 1px solid #ddd;
            /*  border-top-left-radius: 20px;
            border-bottom-right-radius: 20px;*/
            > {
                div[user-caption] {
                    color: #fff;
                    display: flex;
                    flex-direction: column;
                    >span {
                        font-size: 15px;
                    }
                    span[FL] {
                        flex: 1 1 auto;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 15px;
                        margin-bottom: 45px;
                    }
                    span+span {
                        font-size: 38px;
                        font-weight: bold;
                    }
                    div[user] {
                        font-size: 36px;
                        font-weight: bold;
                    }
                }
            }
        }
        span+div.card {
            /*   border-top-left-radius: 20px; */
            margin-right: 15px;
        }
        span+div.card+div {
            margin-right: 15px;
        }
        span+div.card+div+div {
            /*  border-bottom-right-radius: 20px; */
        }
        span[spacer] {
            flex: 1 1 auto;
        }
        .card {
            width: 162px;
            height: 162px;;
            border: 2px solid #ddd;
            padding: 8px;
            color: #fff;
            display: flex;
            flex-direction: column;
            >span {
                flex: 1 1 auto;
                >i {
                    font-size: 36px;
                }
            }
            h4 {
                font-size: 25px;
                margin: 0;
                padding: 0;
                text-align: right;
            }
            span+h4+div {
                font-size: 14px;
                margin-top: 7px;
                text-align: right;
            }
        }
    }
    div[program-list] {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 20px;
        /*   border: 1px solid #ddd; */
        border-top: 4px solid #00338D;
        margin-bottom: 20px;
        >div {
            display: flex;
            padding-left: 18px;
            background-color: #FFFFFF;
            align-items: center;
            padding-top: 18px;
            padding-bottom: 18px;
            >span {
                margin-right: 15px;
            }
            >span {
                >i {
                    color: #00338D;
                }
            }
            span+div {
                color: #111111;
                font-size: 20px;
                font-weight: bold;
            }
        }
        table {
            border-spacing: 0px 5px;
            th,
            td {
                padding: 10px 10px 10px 20px;
                text-align: left;
                border-right: 0;
                border-left: 0;
                >div {
                    display: flex;
                    // justify-content: space-between;
                    >div {
                        display: flex;
                        flex-direction: column;
                        &:nth-child(2) {
                            margin-left: 30px;
                        }
                    }
                }
            }
            th {
                text-transform: uppercase;
                color: #888888;
                >span {
                    font-size: 13px;
                }
            }
            td {
                &:first-child>div {
                    display: flex;
                    flex-direction: column;
                    max-width: 17vw;
                    box-sizing: border-box;
                    span:first-child {
                        color: #00338D;
                        font-weight: bold;
                        margin-top: 10px;
                        a {
                            text-decoration: none;
                            color: #00338D;
                        }
                    }
                    span:nth-child(2) {
                        font-size: 14px;
                        margin-top: 10PX;
                        margin-bottom: 10PX;
                        color: #888888;
                    }
                }
                &:nth-child(2)>div {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    box-sizing: border-box;
                    span:first-child {
                        color: rgba(17, 17, 17, 0.7);
                        font-size: 16px;
                        font-weight: bold;
                        margin-top: 10px;
                    }
                    span:nth-child(2) {
                        font-size: 14px;
                        margin-top: 10PX;
                        margin-bottom: 10PX;
                        color: #888888;
                    }
                }
                &:nth-child(3) {
                    >div {
                        width: 70%;
                        div {
                            span:first-child {
                                color: rgba(17, 17, 17, 0.7);
                                font-size: 16px;
                                font-weight: bold;
                                margin-top: 10px;
                            }
                            span:nth-child(2) {
                                font-size: 14px;
                                margin-top: 10PX;
                                margin-bottom: 10PX;
                                margin-right: 10px;
                                color: #888888;
                            }
                        }
                    }
                }
                &:nth-child(4) {
                    >div {
                        width: 65%;
                        div {
                            span:first-child {
                                color: rgba(17, 17, 17, 0.7);
                                font-size: 16px;
                                font-weight: bold;
                                margin-top: 10px;
                            }
                            span:nth-child(2) {
                                font-size: 14px;
                                margin-top: 10PX;
                                margin-bottom: 10PX;
                                margin-right: 10px;
                                color: #888888;
                            }
                        }
                    }
                }
            }
        }
    }
}

participants {
    background-color: #eee;
    div[participants] {
        display: flex;
        flex-direction: row;
        padding: 0 20px 0 20px;
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    div[participants-list] {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 20px;
        margin-bottom: 100px;
        border: 1px solid #ddd;
        border-top: 4px solid #00338D;
        div[head] {
            div[right-head] {
                display: flex;
                min-width: 400px;
                i {
                    color: #999999;
                    font-weight: normal;
                    font-size: 30px;
                }
                form[search] {
                    flex: 6;
                    input {
                        padding: 5px 10px;
                        width: 94%;
                        /* flex: 1 1 auto; */
                        border: 0;
                        color: #999999;
                        font-size: 16px;
                        opacity: 0.7;
                    }
                }
                form[search]+span {
                    flex: 1;
                }
                form[search]+span+span {
                    flex: 1;
                }
            }
        }
        >div {
            display: flex;
            padding-left: 10px;
            background-color: #FFFFFF;
            padding-top: 20px;
            padding-bottom: 20px;
            >span {
                margin-right: 15px;
                >i {
                    color: #00338D
                }
            }
            span+div {
                color: #111111;
                font-size: 20px;
                font-weight: bold;
                flex: 1 1 auto;
            }
        }
        table {
            th,
            td {
                border: 1px solid #ddd;
                padding: 10px;
                text-align: left;
                border-right: 0;
                border-left: 0;
                &:first-child {
                    width: 15%;
                }
                &:nth-child(2) {
                    width: 18%;
                }
                &:nth-child(3) {
                    width: 15%;
                }
                &:nth-child(4) {
                    width: 30%
                }
                &:nth-child(5) {
                    width: 15%;
                }
                >div {
                    display: flex;
                    justify-content: space-between;
                    >div {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
            th {
                border: 1px solid transparent;
                text-transform: uppercase;
                color: #888888;
                >span {
                    font-size: 13px;
                }
            }
            td {
                &:first-child>div {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    box-sizing: border-box;
                    justify-content: flex-start;
                    span:first-child {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-color: #ddd;
                        display: inline-block;
                        margin-top: 0;
                        margin-right: 20px;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        -ms-border-radius: 50%;
                        -o-border-radius: 50%;
                    }
                    span:nth-child(2) {
                        color: #00338D;
                        font-size: 16px;
                        font-weight: bold;
                        margin-top: 10px;
                        margin-top: 10PX;
                        margin-bottom: 10PX;
                    }
                }
                &:nth-child(2)>div {
                    display: flex;
                    flex-direction: column;
                    max-width: 17vw;
                    box-sizing: border-box;
                    span:first-child {
                        color: #111111;
                        font-size: 17px;
                        font-weight: normal;
                        margin-top: 10px;
                    }
                    span:nth-child(2) {
                        font-size: 14px;
                        margin-top: 10PX;
                        margin-bottom: 10PX;
                        color: #888888;
                    }
                }
                &:nth-child(3) {
                    >div {
                        width: 70%;
                        div {
                            span:first-child {
                                color: #111111;
                                font-size: 17px;
                                font-weight: normal;
                                margin-top: 10px;
                            }
                            span:nth-child(2) {
                                font-size: 14px;
                                margin-top: 10PX;
                                margin-bottom: 10PX;
                                color: #888888;
                            }
                        }
                    }
                }
                &:nth-child(4) {
                    >div {
                        width: 100%;
                        div {
                            span:first-child {
                                color: #00338D;
                                font-size: 16px;
                                font-weight: bold;
                                margin-top: 10px;
                            }
                            span:nth-child(2) {
                                font-size: 14px;
                                margin-top: 10PX;
                                margin-bottom: 10PX;
                                color: #888888;
                            }
                        }
                    }
                }
                &:nth-child(5) {
                    >div {
                        div {
                            span:first-child {
                                color: #111111;
                                font-size: 17px;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
        }
    }
}

settings {
    background-color: #eee;
    div[setting] {
        display: flex;
        flex-direction: column;
        padding: 0 20px 0 30px;
    }
    div[setting-container] {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px 0 30px;
        div[setting-panel] {
            display: flex;
            flex-direction: column;
            background: #fff;
            border: 1px solid #ddd;
            padding: 20px;
            margin-right: 20px;
            margin-bottom: 20px;
            height: 300px;
            width: 42%;
            div:first-child {
                font-size: 18px;
                font-weight: bold;
            }
            p {
                line-height: 24px;
            }
            span[spacer] {
                flex: 1 1 auto;
            }
            span+div {
                padding: 5px;
                margin-bottom: 10px;
                border: 1px solid #ddd;
                color: #00338D;
                font-size: 16px;
                font-weight: bold;
                cursor: pointer;
            }
            span+div+div {
                padding: 5px;
                border: 1px solid #ddd;
                color: #00338D;
                font-size: 16px;
                font-weight: bold;
                cursor: pointer;
            }
            a {
                text-decoration: none;
            }
        }
    }
}

notification {
    background-color: #eee;
    >h1 {
        background-color: #fff;
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 18px 20px;
    }
    ad-hoc {
        display: flex;
        background-color: #eee;
        margin-top: 3px;
        form {
            width: 525px;
            margin: 0 auto;
            .first-row {
                font-size: 20px;
                color: #111111;
                display: flex;
                width: 100%;
                margin: 0 auto;
                padding: 20px 0;
                margin-bottom: 10px;
                box-sizing: border-box;
            }
            .last-row {
                display: flex;
                width: 100%;
                margin: 0 auto;
                padding: 20px 10px;
                margin-bottom: 10px;
                box-sizing: border-box;
                input:first-child {
                    background: transparent;
                    border: 1px solid #00338d;
                    padding: 5px 20px;
                    min-width: 135px;
                    cursor: pointer;
                    color: #00338d;
                }
                input:last-child {
                    background: #00338d;
                    border: 0;
                    color: #fff;
                    margin-left: 15px;
                    min-width: 135px;
                    cursor: pointer;
                    height: 27px;
                }
                input:nth-child(2) {
                    background: transparent;
                    border: 0;
                    color: #00338d;
                    cursor: pointer;
                    outline: 0;
                }
                span {
                    flex: 1 1 auto;
                }
            }
            .msg-body {
                width: 100%;
                margin: 0 auto;
                background-color: #fff;
                padding: 20px 10px;
                margin-bottom: 10px;
                box-sizing: border-box;
            }
            .tab-container>* {
                width: 100%;
            }
            .date-row {
                display: flex;
                justify-content: space-between;
                font-size: 20px;
                color: #111111;
                display: flex;
                background-color: #E5E5E5;
                width: 100%;
                margin: 0 auto;
                padding: 0;
                margin-bottom: 10px;
                box-sizing: border-box;
                i {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 10px;
                    color: #00338D;
                    margin-left: 10px;
                }
                >div {
                    &:first-child,
                    &:last-child {
                        width: 49%;
                        background: #fff;
                        padding: 20px 0px;
                    }
                    mat-form-field {
                        width: 77%;
                    }
                }
            }
            .memeber-list {
                display: flex;
                justify-content: space-between;
                margin-bottom: 1px;
                >span {
                    font-size: 12px;
                    color: #888888;
                }
                div {
                    span:first-child {
                        max-width: 10px;
                        border: 2px solid #00338D;
                        border-radius: 50%;
                        padding: 0px 4px;
                        font-size: 14px;
                        font-weight: bold;
                        color: #00338D;
                        cursor: pointer;
                    }
                    span:last-child {
                        margin-left: 10px;
                        font-size: 14px;
                        font-weight: bold;
                        color: #00338D;
                        cursor: pointer;
                    }
                }
            }
            .chips {
                display: flex;
                justify-content: space-between;
                .mat-chip.mat-standard-chip {
                    color: rgb(0, 51, 141);
                }
                span:last-child {
                    margin-left: 10px;
                    color: #FFF;
                }
            }
        }
        div[notification-list] {
            background-color: #fff;
            div[notification] {
                margin-top: 27px;
                display: flex;
                flex-direction: column;
                padding: 0 20px 0 20px;
                background-color: #fff;
                border-bottom: 1px solid #ddd;
                div[title] {
                    font-weight: bold;
                    font-size: 16px;
                    color: #111111;
                    display: flex;
                    justify-content: space-between;
                    div:first-child {
                        flex: 1;
                        padding: 15px 0;
                    }
                    div+span {
                        padding: 15px 0;
                        display: inline-block;
                        margin-left: 15px;
                        color: #00338d;
                        cursor: pointer;
                    }
                }
                div[details] {
                    padding: 15px 0;
                    font-weight: normal;
                    color: #111111;
                    font-size: 16px;
                    line-height: 24px;
                }
                div[date] {
                    padding: 15px 0;
                    display: flex;
                    div:first-child {
                        margin-right: 20px;
                        span:first-child {
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 10px;
                            color: #00338d;
                        }
                        span:nth-child(2) {
                            font-size: 14px;
                            color: #888888;
                        }
                    }
                    div:nth-child(2) {
                        span:first-child {
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 10px;
                            color: #00338d;
                        }
                        span:nth-child(2) {
                            font-size: 14px;
                            color: #888888;
                        }
                    }
                }
                div[memeber-list] {
                    padding: 15px 0 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    div:first-child {
                        display: flex;
                        align-items: center;
                        span:first-child {
                            color: #00338D;
                            cursor: pointer;
                        }
                        span:nth-child(2) {
                            margin-left: 10px;
                            font-size: 14px;
                            font-weight: bold;
                            color: #00338D;
                            cursor: pointer;
                        }
                    }
                    div+span {
                        font-size: 14px;
                        color: #888888;
                    }
                }
                +div[notification] {
                    margin-top: 27px;
                }
            }
        }
        button {
            color: #00338d;
            font-size: 28px;
        }
        .mat-tab-label-content {
            font-size: 16px;
            color: #111111;
        }
        .mat-ink-bar {
            height: 5px !important;
        }
        mat-tab-header {
            padding: 20px;
            background-color: #fff;
            padding-left: 20px;
        }
        .mat-form-field-subscript-wrapper {
            margin-top: -3.458333em !important;
        }
        mat-chip-list {
            span {
                min-width: 7px;
                font-size: 10px;
                font-weight: 700;
                background-color: #00338D;
                padding: 3px 5px;
                border-radius: 50%;
                color: #fff;
                margin-left: 10px;
                cursor: pointer;
            }
        }
        .mat-tab-label-active {
            font-weight: bold;
            color: #00338D;
            opacity: 1 !important;
            font-size: 18px;
        }
        .mat-form-field-infix {
            width: 300px;
        }
    }
}

div[notification-template] {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #ddd;
    border-top: 1px solid #00338D;
    width: 97%;
    background-color: #eee;
    div[head] {
        border-top: 4px solid #00338D;
        div[right-head] {
            display: flex;
            i {
                color: #999999;
                font-weight: normal;
                font-size: 30px;
                cursor: pointer;
            }
            form[search] {
                padding: 0 !important;
                flex: 6;
                input {
                    padding: 5px 10px;
                    border: 0;
                    color: #999999;
                    font-size: 16px;
                    opacity: 0.7;
                    width: 70% !important;
                    outline: 0;
                }
            }
            form[search]+span {
                flex: 1;
            }
            form[search]+span+span {
                flex: 1;
            }
        }
    }
    >div {
        display: flex;
        padding-left: 0;
        background-color: #FFFFFF;
        padding-top: 20px;
        padding-bottom: 20px;
        >span {
            margin-right: 15px;
            >i {
                color: #00338D;
            }
        }
        span+div {
            color: #111111;
            font-size: 20px;
            font-weight: bold;
            flex: 1 1 auto;
        }
    }
    >table {
        width: 100%;
        border-spacing: 0;
        background-color: #E5E5E5;
        border-bottom: 5px solid #e5e5e5;
        th,
        td {
            border: 1px solid #ddd;
            padding: 10px;
            text-align: left;
            border-right: 0;
            border-left: 0;
        }
        th,
        td {
            &:nth-child(1) {
                width: 20%;
            }
            &:nth-child(2) {
                width: 60%;
            }
            &:nth-child(3) {
                width: 20%;
            }
        }
        >tbody {
            &:hover {
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13), 0 0 10px 0 rgba(0, 0, 0, 0.05);
                cursor: pointer;
            }
            >tr {
                &:nth-child(1) {
                    >td {
                        &:nth-child(1) {
                            >div {
                                display: flex;
                                flex-direction: row;
                                width: 100%;
                                box-sizing: border-box;
                                justify-content: flex-start;
                                color: #111111;
                                font-weight: bold;
                                font-size: 16px;
                            }
                        }
                        &:nth-child(2) {
                            >div {
                                &:nth-child(1) {
                                    padding: 10px 0;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    width: 95%;
                                    border-bottom: 1px solid #ddd;
                                }
                                &:nth-child(2) {
                                    display: none;
                                    textarea {
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        color: #111111;
                                    }
                                    div[insert] {
                                        position: relative;
                                        top: -10px;
                                        span:first-child {
                                            max-width: 10px;
                                            border: 2px solid #00338D;
                                            border-radius: 50%;
                                            padding: 0px 4px;
                                            font-size: 14px;
                                            font-weight: bold;
                                            color: #00338D;
                                            cursor: pointer;
                                        }
                                        span:last-child {
                                            margin-left: 10px;
                                            font-size: 14px;
                                            font-weight: bold;
                                            color: #00338D;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }
                        &:nth-child(3) {
                            >div {
                                display: flex;
                                justify-content: space-between;
                            }
                            i {
                                vertical-align: middle;
                                cursor: pointer;
                                color: #888888;
                                &.active {
                                    transform: rotate(180deg);
                                }
                            }
                            i {
                                vertical-align: middle;
                                cursor: pointer;
                                color: #00338d;
                            }
                        }
                    }
                }
                &:nth-child(2) {
                    display: none;
                    >td {
                        &:nth-child(1) {
                            >span {
                                font-size: 14px;
                                color: #888888;
                                display: inline-block;
                                padding: 10px;
                            }
                            >div {
                                padding: 10px 0;
                            }
                        }
                        &:nth-child(3) {
                            div[save-change] {
                                display: flex;
                                justify-content: flex-end;
                                input:first-child {
                                    background: transparent;
                                    border: 0;
                                    color: #00338d;
                                    cursor: pointer;
                                    outline: 0;
                                    padding: 5px;
                                    font-size: 14px;
                                }
                                input:last-child {
                                    background: #00338d;
                                    border: 0;
                                    color: #fff;
                                    margin-left: 15px;
                                    min-width: 145px;
                                    cursor: pointer;
                                    padding: 5px;
                                    font-size: 14px;
                                }
                            }
                            div[save-success] {
                                display: flex;
                                justify-content: flex-end;
                                input:first-child {
                                    background: transparent;
                                    border: 0;
                                    color: #00338d;
                                    cursor: pointer;
                                    outline: 0;
                                    padding: 5px;
                                    font-size: 14px;
                                }
                                input+span {
                                    padding: 5px;
                                    font-size: 14px;
                                    color: #009A44;
                                    font-weight: bold;
                                }
                                input+span+span {
                                    i {
                                        color: #009A44;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.edit-mode {
                >tr {
                    &:nth-child(1) {
                        >td {
                            &:nth-child(2) {
                                >div {
                                    &:nth-child(1) {
                                        display: none;
                                    }
                                    &:nth-child(2) {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(2) {
                        display: table-row;
                    }
                }
            }
            mat-form-field {
                width: 95%;
            }
            .mat-form-field-subscript-wrapper {
                margin-top: 0 !important;
            }
            .mat-form-field-subscript-wrapper {
                margin-top: 0.54166667em !important;
                top: calc(100% - 1.66666667em) !important;
            }
            .mat-expansion-panel-header-description {
                flex-grow: 1 !important;
            }
            mat-form-field.edit-mode {
                display: none;
            }
        }
    }
}

admin-program {
    program-overview {
        display: block;
        background-color: #eee;
        padding: 20px;
        div[date-timeline] {
            position: relative;
            >div {
                position: absolute;
                background: #eee;
                display: flex;
                align-items: center;
                padding: 10px 30px;
                left: 35%;
                top: -20px;
                span {
                    font-size: 15px;
                    color: #111111;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 17px;
                    font-weight: bold;
                }
                span:nth-child(2) {
                    margin: 0 10px;
                }
                span:last-child:after {
                    // content: " ";
                    display: inline-block;
                    width: 0;
                    height: 0;
                    margin-left: 18px;
                    vertical-align: middle;
                    border-top: 6px solid;
                    border-right: 6px solid transparent;
                    border-left: 6px solid transparent;
                    cursor: pointer;
                    color: #888888;
                }
            }
        }
        div[view-mode] {
            padding: 40px 20px 30px 0;
            height: 40px;
            display: flex;
            border-bottom: 2px solid #D5D5D5;
            align-items: center;
            span:first-child {
                font-size: 32px;
                color: #111111;
                letter-spacing: 0;
                font-weight: bold;
                margin-right: 20px;
            }
            span:nth-child(2) {
                font-size: 13px;
                color: #F68D2E;
                letter-spacing: 0.15px;
                font-weight: bold;
                display: inline-block;
                min-width: 90px;
                border-radius: 2px;
                background: rgba(246, 141, 46, 0.08);
                padding: 5px;
            }
            span[spacer] {
                flex: 1 1 auto;
            }
            span[divider] {
                display: inline-block;
                margin: 0 10px;
                border-right: 1.5px solid #D5D5D5;
                height: 30px;
            }
            span[spacer]+span {
                display: inline-block;
                margin: 0 10px;
                i {
                    color: #888888;
                    cursor: pointer;
                    &.active {
                        color: #00338D;
                    }
                }
            }
            span[spacer]+span+span {
                display: inline-block;
                margin: 0 10px;
                i {
                    color: #888888;
                    cursor: pointer;
                    &.active {
                        color: #00338D;
                    }
                }
            }
            span[divider]+span {
                display: inline-block;
                margin: 0 10px;
                i {
                    color: #888888;
                    cursor: pointer;
                    &.active {
                        color: #00338D;
                    }
                }
            }
            span[divider]+span+span {
                display: inline-block;
                margin-left: 10px;
                i {
                    color: #888888;
                    cursor: pointer;
                    &.active {
                        color: #00338D;
                    }
                }
            }
        }
        div[list-group] {
            height: 100px;
            border-top: 2px solid #ddd;
            padding: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background: #fff;
            >div {
                display: flex;
                flex-direction: column;
                width: 50%;
                background: rgba(0, 51, 141, 0.05);
                height: 81px;
                align-items: flex-start;
                justify-content: center;
                padding: 10px;
                padding-left: 25px;
                &:not(:last-child) {
                    margin-right: 15px;
                }
                span:first-child {
                    font-size: 16px;
                    color: #111111;
                    letter-spacing: 0;
                    opacity: 1;
                    margin-bottom: 5px;
                    word-break: break-word;
                    font-weight: bold;
                    width: 100%;
                }
                span:nth-child(2) {
                    font-size: 14px;
                    color: #888888;
                    letter-spacing: 0;
                }
            }
        }
        div[overview-dashboard] {
            display: flex;
            justify-content: space-between;
            border-top: 3px solid #00338d;
            background: #fff;
            div[right-section] {
                padding: 20px;
                div[top-cards] {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;
                    >div {
                        width: 165px;
                        height: 90px;
                        border: 2px solid rgba(0, 51, 141, 0.08);
                        display: flex;
                        padding: 10px;
                        >span {
                            flex: 1 1 auto;
                            i {
                                color: #00338d;
                            }
                        }
                        span+div {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-end;
                            span:first-child {
                                font-size: 32px;
                                color: #00338D;
                                letter-spacing: -0.44px;
                                font-weight: bold;
                            }
                            span:nth-child(2) {
                                font-size: 16px;
                                color: #888888;
                                letter-spacing: 0;
                                line-height: 20px;
                            }
                        }
                    }
                }
                div[bottom-cards] {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 2px solid rgba(0, 51, 141, 0.08);
                    padding: 10px;
                    height: 120px;
                    >div {
                        width: 165px;
                        height: 60px;
                        display: flex;
                        padding: 10px;
                        &:not(:last-child) {
                            border-right: 2px solid rgba(0, 51, 141, 0.08);
                        }
                        >span {
                            flex: 1 1 auto;
                            i {
                                color: #00338d;
                            }
                        }
                        span+div {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-end;
                            span:first-child {
                                font-size: 32px;
                                color: #00338D;
                                letter-spacing: -0.44px;
                                font-weight: bold;
                            }
                            span:nth-child(2) {
                                font-size: 16px;
                                color: #888888;
                                letter-spacing: 0;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }
            div[library] {
                height: 60px;
                width: 60px;
                background: #e5e5e5;
                display: flex;
                align-items: center;
                border-radius: 50%;
                i {
                    font-size: 28px;
                    margin: 0 auto;
                    color: #00338d;
                }
            }
            div[library]+div {
                // padding: 10px;
                margin-left: 15px;
                display: flex;
                flex-direction: column;
                span:first-child {
                    font-size: 20px;
                    color: #111111;
                    letter-spacing: 0;
                    line-height: 25px;
                    font-weight: bold;
                }
                span:nth-child(2) {
                    font-size: 16px;
                    color: #888888;
                    letter-spacing: 0;
                    line-height: 20px;
                }
            }
            >div:first-child {
                >div {
                    display: flex;
                    align-items: center;
                }
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 20px 25px;
            }
            div+div {
                span[icl] {
                    font-size: 36px;
                    color: #00338D;
                    letter-spacing: 0;
                    text-align: left;
                    line-height: 54px;
                    display: inline-block;
                    max-width: 55ch;
                    overflow-wrap: break-word;
                    font-weight: bold;
                }
            }
        }
        div[level] {
            padding: 20px 0;
            span {
                font-size: 20px;
                color: #111111;
                letter-spacing: 0;
                font-weight: bold;
            }
        }
        div[card-section-morning] {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            >div {
                display: flex;
                background: #fff;
                padding: 20px;
                height: 75px;
                width: 28%;
                margin-bottom: 20px;
                &:hover {
                    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13), 0 0 10px 0 rgba(0, 0, 0, 0.05);
                }
                div[a] {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-right: 20px;
                    i {
                        color: #999999;
                        &.active {
                            color: #F68D2E;
                        }
                        &.green {
                            color: #00A3A1;
                        }
                    }
                }
                div[b] {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    div:first-child {
                        margin-bottom: 5px;
                        span:first-child {
                            color: #999999;
                            font-weight: bold;
                            width: 75%;
                            overflow: hidden;
                            display: inline-block;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            &.active {
                                color: #00338D;
                            }
                        }
                    }
                    div:nth-child(2) {
                        span {
                            color: #888888;
                            font-size: 14px;
                        }
                        span:nth-child(2) {
                            margin: 0 5px;
                        }
                    }
                }
            }
        }
    }
    program-classes {
        width: 100%;
        div[left] {
            > div {
                color: #111;
                font-size: 20px;
                font-weight: bold;
                flex: 1 1 auto;
                padding: 10px;
                > span {
                    margin-left: 10px;
                    top: -5px;
                    position: relative;
                }
            }
        }
        div[Class-list] {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 20px;
            border-top: 4px solid #00338D;
            div[mode="open"] {
                div[close].undo {
                    width: 10%;
                    display: flex;
                    align-items: center;
                    flex: 0;
                }
                div[close].undo span[save] {
                    flex: 2;
                    font-size: 14px;
                    color: rgba(0, 154, 68, 0.99);
                    letter-spacing: 0;
                    font-weight: bold;
                }
                div[close].undo span[save]+span i {
                    color: rgba(0, 154, 68, 0.99);
                }
                div[left],
                div[right] {
                    display: none;
                }
            }
            div[mode="close"] {
                height: 70px;
                div[left],
                div[right] {
                    display: flex;
                    height: 70px;
                    span {
                        i {
                            display: flex;
                            cursor: pointer;
                        }
                    }
                }
                div[right]+span {
                    display: none;
                }
                span+div {
                    display: none !important;
                }
                span {
                    i {
                        display: none;
                    }
                }
                div[close] {
                    display: none !important;
                }
                div[right-head] {
                    display: none !important;
                }
            }
            div[head] {
                div[right-head] {
                    display: flex;
                    flex-basis: 25%;
                    padding: 10px;
                    i {
                        color: #999999;
                        font-weight: normal;
                        font-size: 30px;
                    }
                    form[search] {
                        flex: 6;
                        input {
                            padding: 5px 10px;
                            width: 94%;
                            border: 0;
                            color: #999999;
                            font-size: 16px;
                            opacity: 0.7;
                            outline: 0;
                        }
                    }
                    form[search]+span {
                        flex: 1;
                    }
                    form[search]+span+span {
                        flex: 1;
                    }
                }
                div[close] {
                    padding: 10px;
                    border-left: 2px solid #ddd;
                    span {
                        i {
                            color: #00338d;
                            font-weight: normal;
                            font-size: 30px;
                            cursor: pointer;
                        }
                    }
                }
            }
            div[opne="false"] {
                display: none;
            }
            div[opne="true"] {
                flex-direction: column;
                th,
                td {
                    width: 50%;
                }
            }
            >div {
                display: flex;
                background-color: #FFFFFF;
                height: 70px;
                align-items: center;
                >span {
                    padding: 10px 10px 10px 18px;
                    >i {
                        color: #00338d;
                    }
                }
                span+div {
                    color: #111111;
                    font-size: 20px;
                    font-weight: bold;
                    flex: 1 1 auto;
                    padding: 10px;
                }
            }
            div[left] {
                flex: 1;
            }
            div[right] {
                display: flex;
                width: 27%;
                background: #00338d;
                padding: 10px 0px;
                align-items: center;
                section[cancel-message] {
                    &:after {
                        content: '';
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        width: 0;
                        height: 0;
                        border-top: solid 14px #ffffff;
                        border-left: solid 14px transparent;
                        border-right: solid 14px transparent;
                    }
                    position: absolute;
                    width: 235px;
                    height: 100px;
                    top: 71px;
                    right: 63px;
                    background: #FFFFFF;
                    box-shadow: 0 2px 5px 0 rgba(0,
                    0,
                    0,
                    0.26),
                    0 2px 10px 0 rgba(0,
                    0,
                    0,
                    0.16);
                    border-radius: 2px;
                    padding: 10px;
                    display: flex;
                    z-index:999;
                    >div {
                        justify-content: space-around;
                        &:first-child {
                            display: flex;
                            flex-direction: column;
                            span:first-child {
                                font-size: 16px;
                                color: #111111;
                                letter-spacing: 0;
                                text-align: center;
                                line-height: 22px;
                                font-weight: bold;
                            }
                            article {
                                display: flex;
                                justify-content: center;
                                button {
                                    &:first-child {
                                        background: transparent;
                                        color: #00338D;
                                        outline: 0;
                                        cursor: pointer;
                                        font-size: 14px;
                                        border: 0;
                                        padding: 4px;
                                        margin-right: 4px;
                                    }
                                    &:nth-child(2) {
                                        color: #FFF;
                                        background: #00338D;
                                        font-size: 14px;
                                        border: 0;
                                        padding: 4px;
                                        margin-right: 4px;
                                        width: 130px;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
                span {
                    color: #FFF;
                    display: flex;
                    font-size: 20px;
                    font-weight: bold;
                    &.disable {
                        display: none;
                    }
                    button {
                        margin-right: 10px;
                        margin-right: 10px;
                        font-size: 13px !important;
                        &.disable {
                            display: none;
                        }
                        &:nth-of-type(1) {
                            background: transparent;
                            color: #fff;
                            outline: 0;
                            cursor: pointer;
                            font-size: 14px;
                            border: 0;
                            padding: 4px;
                            margin-right: 10px;
                        }
                        &:nth-of-type(2) {
                            color: #00338D;
                            font-size: 14px;
                            border: 0;
                            padding: 4px;
                            margin-right: 4px;
                            width: 82px;
                            cursor: pointer;
                            justify-content: center;
                        }
                    }
                    &:first-child {
                        margin-left: 20px;
                        margin-right: 10px;
                    }
                    &:nth-child(2) {
                        flex: 1 1 auto;
                    }
                    &:nth-child(3),
                    &:last-child {
                        cursor: pointer;
                        margin-right: 10px;
                    }
                }
            }
            table[mode="edit"] {
                th,
                td {
                    &:nth-child(1) {
                        display: table-cell;
                        width: 10%;
                    }
                    &:nth-child(2) {
                        white-space: pre-line;
                        width: 12%;
                        >div {
                            max-width: fit-content;
                            white-space: pre-line;
                            span:first-child {
                                font-weight: bold;
                                color: #00338D;
                                cursor: pointer;
                            }
                        }
                    }

                    &:nth-child(3) {
                        display: table-cell;
                        width: 10%;
                    }
                    &:nth-child(4),
                    &:nth-child(5)  {
                        display: table-cell;
                        width: 10%;
                        > div {
                            position: relative;
                            top: 10px;
                            > div {
                                margin-left: 10px;
                                top: -10px;
                                position: relative;
                                span:first-child {
                                    color: inherit;
                                    font-weight: inherit;
                                }
                                span:last-child {
                                    margin-top: 0px;
                                }
                            }
                        }
                    }
                    // &:nth-child(5) {
                    //     display: table-cell;
                    //     width: 10%;
                    // }
                    &:nth-child(6) {
                        display: table-cell;
                        width: 10%;
                    }
                    &.last-view {
                        width: 23%;
                        border-left: 4px solid #ddd;
                    }
                }
                th {
                    >div {
                        >div {
                            &:first-child {
                                flex: 1;
                                display: flex;
                                flex-direction: row;
                                span:first-child {
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                    &.last-view {
                        span {
                            text-transform: none;
                            color: #000;
                        }
                    }
                }
            }
            table[mode="view"] {
                .last-view {
                    display: none;
                }
                .edit {
                    display: table-cell;
                    >div {
                        span {
                            margin-right: 10px;
                            display: flex;
                            align-items: center;
                            i {
                                color: #00338d;
                            }
                        }
                    }
                }
            }
            th,
            td {
                width: 12px;
                padding: 10px 10px 10px 20px;
                text-align: left;
                border-right: 0;
                border-left: 0;
                >div {
                    display: flex;
                    justify-content: flex-start;
                    >div {
                        display: flex;
                        flex-direction: column;
                    }
                }
                i {
                    vertical-align: middle;
                    cursor: pointer;
                    color: #888888;
                    &.active {
                        transform: rotate(180deg);
                    }
                }
                img {
                    vertical-align: middle;
                    cursor: pointer;
                    margin-left: 7px;
                    &.active {
                        transform: rotate(180deg);
                    }
                }
            }
            th {
                span {
                    font-size: 13px;
                    color: #888888;
                    text-transform: uppercase;
                }
            }
            td {
                &.red {
                    border-left: 3px solid #F68D2E;
                    span.alert {
                        padding: 0 10px;
                        min-width: 100px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(51, 51, 51, 0.08);
                        border-radius: 2px;
                        color: #F68D2E;
                        font-size: 13px;
                        font-weight: bold;
                        letter-spacing: 0.15px;
                    }
                }
                &.green {
                    border-left: 3px solid #00A3A1;
                    span.active {
                        padding: 0 10px;
                        min-width: 100px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(51, 51, 51, 0.08);
                        border-radius: 2px;
                        color: #00A3A1;
                        font-size: 13px;
                        font-weight: bold;
                        letter-spacing: 0.15px;
                    }
                }
                &.disable {
                    border-left: 3px solid #333333;
                }
                &:first-child {
                    >div {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        box-sizing: border-box;
                        justify-content: flex-start;
                        span:first-child {
                            margin-right: 0;
                        }
                        span:nth-child(2) {
                            padding: 0 10px;
                            min-width: 100px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 2px;
                            font-size: 13px;
                            font-weight: bold;
                            letter-spacing: 0.15px;
                            &.alert {
                                background: rgba(246, 141, 46, 0.08);
                                color: #F68D2E;
                            }
                            &.active {
                                background: rgba(0, 163, 161, 0.08);
                                color: #00A3A1;
                            }
                            &.gray {
                                background: rgba(51, 51, 51, 0.08);
                                color: #333333;
                            }
                        }
                    }
                }&:nth-child(2)>div {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    max-width: 1vw;
                    margin-right: 0;
                    opacity:1;
                    white-space: nowrap;
                    align-items: center;
                    span:first-child {
                    margin-top: 4px;
                    vertical-align: text-bottom;
                    margin-bottom: 8px;
                    }
                    span:nth-child(2) {
                     /* vertical-align: text-bottom;
                      opacity:0;
                      white-space: nowrap;
                      &:hover {
                            display: block;
                            opacity:1;
                            white-space: nowrap;
                        } jpedrero1675
                      */
                    }


                }
               &:nth-child(2)
                    >div {
                        span:first-child {
                             display: flex;
                            align-items: center;
                        }
                    }
                &:nth-child(4)>div {
                    display: flex;
                    flex-direction: inherit;
                    width: 100%;
                    box-sizing: border-box;
                    max-width: 14vw;
                    span:first-child {
                        font-size: 16px;
                        font-weight: bold;
                        color: #00338D;
                        cursor: pointer;
                    }
                    span:nth-child(4) {
                        font-size: 14px;
                        margin-top: 10PX;
                        margin-bottom: 10PX;
                        color: #888888;
                    }
                }
                &:nth-child(5) {
                    >div {
                        width: 100%;
                        div {
                            span:first-child {
                                color: #111111;
                                font-size: 16px;
                                font-weight: normal;
                            }
                            span:nth-child(2) {
                                font-size: 14px;
                                margin-top: 10PX;
                                margin-bottom: 10PX;
                                color: #888888;
                            }
                        }
                    }
                }
                &:nth-child(6) {
                    >div {
                        span:first-child {
                            margin-right: 10px;
                            display: flex;
                            align-items: center;
                            i {
                                color: #00338d;
                                ;
                            }
                        }
                    }
                    span+div {
                        span:first-child {
                            margin-bottom: 5px;
                        }
                        span:last-child {
                            font-size: 14px;
                            color: #888888;
                        }
                    }
                }
                &:nth-child(7) {
                    >div {
                        span:first-child {
                            margin-right: 10px;
                            display: flex;
                            align-items: center;
                            i {
                                color: #00338d;
                                ;
                            }
                        }
                    }
                    span+div {
                        span:first-child {
                            margin-bottom: 5px;
                        }
                        span:last-child {
                            font-size: 14px;
                            color: #888888;
                        }
                    }
                }
                &:nth-child(6) {
                    >div {
                        span:first-child {
                            margin-right: 10px;
                            display: flex;
                            align-items: center;
                            i {
                                color: #00338d;
                                ;
                            }
                        }
                    }
                    span+div {
                        span:first-child {
                            margin-bottom: 5px;
                        }
                        span:last-child {
                            font-size: 14px;
                            color: #888888;
                        }
                    }
                }
                &:last-child {
                    >div {
                        div:first-child {
                            justify-content: space-between;
                            span {
                                font-size: 14px;
                                color: #888888;
                                margin-right: 10px;
                            }
                        }
                        div:nth-child(2) {
                            justify-content: space-between;
                        }
                    }
                }
            }
        }
    }

    program-sessions {
        width: 100%;
        div[Class-list] {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 20px;
            border: 1px solid #ddd;
            border-top: 4px solid #00338D;
            div[mode="open"] {
                height: 50px;
                div[left],
                div[right] {
                    display: none;
                }
            }
            div[mode="close"] {
                height: 50px;
                div[left],
                div[right] {
                    display: flex;
                    span {
                        i {
                            display: flex;
                            cursor: pointer;
                        }
                    }
                }
                div[right]+span {
                    display: none;
                }
                span+div {
                    display: none !important;
                }
                span {
                    i {
                        display: none;
                    }
                }
                div[close] {
                    display: none !important;
                }
                div[right-head] {
                    display: none !important;
                }
            }
            table[mode="view"] {
                tr {
                    &:hover {
                        // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13), 0 0 10px 0 rgba(0, 0, 0, 0.05);
                    }
                }
                th {
                    &.edit {
                        span {
                            font-size: 13px;
                            color: #888888;
                            text-transform: uppercase;
                        }
                    }
                }
                .edit {
                    display: table-cell;
                    >div {
                        span {
                            margin-right: 10px;
                            display: flex;
                            align-items: center;
                            i {
                                color: #00338d;
                            }
                        }
                    }
                }
                .default {
                    display: none;
                }
                .view {
                    display: none;
                }
                .last-view {
                    display: none;
                }
            }
            td {
                &.red {
                    border-left: 3px solid #F68D2E;
                    span.alert {
                        padding: 0 10px;
                        min-width: 100px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(51, 51, 51, 0.08);
                        border-radius: 2px;
                        color: #F68D2E;
                        font-size: 13px;
                        font-weight: bold;
                        letter-spacing: 0.15px;
                    }
                }
                &.green {
                    border-left: 3px solid #00A3A1;
                    span.active {
                        padding: 0 10px;
                        min-width: 100px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(51, 51, 51, 0.08);
                        border-radius: 2px;
                        color: #00A3A1;
                        font-size: 13px;
                        font-weight: bold;
                        letter-spacing: 0.15px;
                    }
                }
                &.disable {
                    border-left: 3px solid #333333;
                }
            }
            div[head] {
                div[right-head] {
                    display: flex;
                    flex-basis: 25%;
                    padding: 10px;
                    i {
                        color: #999999;
                        font-weight: normal;
                        font-size: 30px;
                        cursor: pointer;
                    }
                    form[search] {
                        flex: 6;
                        input {
                            padding: 5px 10px;
                            width: 94%;
                            /* flex: 1 1 auto; */
                            border: 0;
                            color: #999999;
                            font-size: 16px;
                            opacity: 0.7;
                            outline: 0;
                            &::placeholder {
                                opacity: 0.7;
                                font-size: 16px;
                                color: #999999;
                            }
                        }
                    }
                    form[search]+span {
                        flex: 1;
                    }
                    form[search]+span+span {
                        flex: 1;
                    }
                }
                div[close] {
                    padding: 10px;
                    border-left: 2px solid #ddd;
                    span {
                        i {
                            color: #00338d;
                            font-weight: normal;
                            font-size: 30px;
                            cursor: pointer;
                        }
                    }
                }
            }
            div[opne="false"] {
                display: none;
            }
            div[opne="true"] {
                flex-direction: column;
                th,
                td {
                    width: 50%;
                }
            }
            >div {
                >span {
                    >i {
                        color: #00338d;
                    }
                }
            }
            td {
                border-bottom: 2px solid #ddd;
                &.red {
                    border-left: 3px solid #F68D2E;
                }
            }
            td:first-child {
                >div {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    box-sizing: border-box;
                    justify-content: flex-start;
                    span:nth-child(2) {
                        padding: 0 10px;
                        min-width: 100px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 2px;
                        font-size: 13px;
                        font-weight: bold;
                        letter-spacing: 0.15px;
                        &.alert {
                            background: rgba(246, 141, 46, 0.08);
                            color: #F68D2E;
                            text-transform: uppercase;
                        }
                        &.active {
                            background: rgba(0, 163, 161, 0.08);
                            color: #00A3A1;
                            text-transform: uppercase;
                        }
                        &.gray {
                            background: rgba(51, 51, 51, 0.08);
                            color: #333333;
                            text-transform: uppercase;
                        }
                    }
                }
            }
            td:nth-child(2)>div {
                display: flex;
                flex-direction: column;
                max-width: 10vw;
                box-sizing: border-box;
                span:first-child {
                    font-size: 16px;
                    font-weight: bold;
                    color: #00338D;
                }
                span:nth-child(2) {
                    font-size: 14px;
                    margin-top: 10PX;
                    margin-bottom: 10PX;
                    color: #888888;
                }
            }
            td:nth-child(3) {
                >div {
                    width: 70%;
                    div {
                        span:first-child {
                            color: #111111;
                            font-size: 16px;
                            font-weight: normal;
                        }
                        span:nth-child(2) {
                            font-size: 14px;
                            margin-top: 10PX;
                            margin-bottom: 10PX;
                            color: #888888;
                        }
                    }
                }
            }
            td:nth-child(4) {
                >div {
                    span:first-child {
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        i {
                            color: #00338d;
                            ;
                        }
                    }
                }
                span+div {
                    span:first-child {
                        margin-bottom: 5px;
                    }
                    span:last-child {
                        font-size: 14px;
                        color: #888888;
                    }
                }
            }
            td:nth-child(5) {
                >div {
                    span:first-child {
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        i {
                            color: #00338d;
                            ;
                        }
                    }
                }
                span+div {
                    span:first-child {
                        margin-bottom: 5px;
                    }
                    span:last-child {
                        font-size: 14px;
                        color: #888888;
                    }
                }
            }
            td:nth-child(6) {
                >div {
                    span:first-child {
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        i {
                            color: #00338d;
                            ;
                        }
                    }
                }
                span+div {
                    span:first-child {
                        margin-bottom: 5px;
                    }
                    span:last-child {
                        font-size: 14px;
                        color: #888888;
                    }
                }
            }
            td:last-child {
                >div {
                    div:first-child {
                        justify-content: space-between;
                        span {
                            font-size: 14px;
                            color: #888888;
                            margin-right: 10px;
                        }
                    }
                    div:nth-child(2) {
                        justify-content: space-between;
                    }
                }
            }
            th,
            td {
                width: 13%;
                padding: 8px;
                text-align: left;
                border-right: 0;
                border-left: 0;
                >div {
                    display: flex;
                    justify-content: flex-start;
                    >div {
                        display: flex;
                        flex-direction: column;
                    }
                }
                i {
                    vertical-align: middle;
                    // cursor: pointer;
                    color: #888888;
                    &.active {
                        transform: rotate(180deg);
                    }
                }
            }
            >div {
                display: flex;
                background-color: #FFFFFF;
                >span {
                    padding: 10px 10px 10px 18px;
                }
                span+div {
                    color: #111111;
                    font-size: 20px;
                    font-weight: bold;
                    flex: 1 1 auto;
                    padding: 10px;
                }
            }
        }
        div[left] {
            width: 50%;
            display: flex;
            align-items: center;
            padding: 10px;
            span[spacer] {
                flex: 1 1 auto;
            }
            input {
                padding: 5px;
                width: 60%;
                border: 0;
                outline: 0;
                &::placeholder {
                    opacity: 0.7;
                    font-size: 16px;
                    color: #999999;
                }
            }
            span {
                i {
                    color: #999999;
                    opacity: 0.8;
                    cursor: pointer;
                }
            }
        }
        div[right] {
            display: flex;
            width: 50%;
            background: #00338d;
            padding: 10px;
            align-items: center;
            span {
                color: #FFF;
                display: flex;
                font-size: 20px;
                &.disable {
                    display: none;
                }
                button {
                    margin-right: 10px;
                    margin-right: 10px;
                    font-size: 13px !important;
                    &.disable {
                        display: none;
                    }
                    &:nth-of-type(1) {
                        color: white;
                        background: transparent;
                        border: none;
                    }
                    &:nth-of-type(2) {
                        color: #00338d;
                        padding: 5px 27px;
                    }
                }
            }
            span:first-child {
                margin-right: 10px;
            }
            span:nth-child(2) {
                flex: 1 1 auto;
            }
            span:last-child {
                cursor: pointer;
            }
        }
    }
    program-sessions div[Class-list] table[mode="view"] th:not(:nth-child(8)) {
        span,
        i {
            cursor: pointer;
        }
    }
    program-registration {
        width: 100%;
        div[registration-list] {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 20px;
            border: 1px solid #ddd;
            border-top: 4px solid #00338D;
            div[mode="open"] {
                height: 50px;
                div[left],
                div[right] {
                    display: none;
                }
            }
            div[mode="close"] {
                height: 50px;
                div[left] {
                    width: 34%;
                }
                div[right] {
                    width: 66%;
                }
                div[left],
                div[right] {
                    display: flex;
                    >section {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        span:nth-child(2) {
                            font-size: 14px;
                        }
                    }
                    span {
                        i {
                            display: flex;
                        }
                    }
                }
                div[right]+span {
                    display: none;
                }
                span+div {
                    display: none !important;
                }
                span {
                    i {
                        display: none;
                    }
                }
                div[close] {
                    display: none !important;
                }
                div[right-head] {
                    display: none !important;
                }
            }
            /* Style code for table on mode basis */
            table[mode="view"] {
                tr {
                    &:hover {
                        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13), 0 0 10px 0 rgba(0, 0, 0, 0.05);
                    }
                }
                th {
                    &.edit {
                        span {
                            font-size: 13px;
                            color: #888888;
                            text-transform: uppercase;
                        }
                    }
                }
                .edit {
                    display: table-cell;
                    >div {
                        span {
                            margin-right: 10px;
                            display: flex;
                            align-items: center;
                            i {
                                color: #00338d;
                            }
                        }
                    }
                }
                .default {
                    display: none;
                }
                .view {
                    display: none;
                }
                .last-view {
                    display: none;
                }
            }
            td {
                &.status {
                    >div {
                        span:first-child {
                            padding: 4px 8px;
                            min-width: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            border-radius: 2px;
                            font-size: 13px;
                            font-weight: bold;
                            letter-spacing: 0.15px;
                        }
                    }
                }
                &.name {
                    span:first-child {
                        display: inline-block;
                        width: 31px;
                        height: 31px;
                        background: #EEEEEE;
                        border-radius: 50%;
                    }
                    span:nth-child(2) {
                        font-size: 16px;
                        font-weight: bold;
                        color: #00338D;
                    }
                }
            }
            div[head] {
                div[right-head] {
                    display: flex;
                    flex-basis: 25%;
                    padding: 10px;
                    i {
                        color: #999999;
                        font-weight: normal;
                        font-size: 30px;
                        cursor: pointer;
                    }
                    form[search] {
                        flex: 6;
                        input {
                            padding: 5px 10px;
                            width: 94%;
                            /* flex: 1 1 auto; */
                            border: 0;
                            color: #999999;
                            font-size: 16px;
                            opacity: 0.7;
                            outline: 0;
                            &::placeholder {
                                opacity: 0.7;
                                font-size: 16px;
                                color: #999999;
                            }
                        }
                    }
                    form[search]+span {
                        flex: 1;
                    }
                    form[search]+span+span {
                        flex: 1;
                    }
                }
                div[close] {
                    padding: 10px;
                    border-left: 2px solid #ddd;
                    span {
                        i {
                            color: #00338d;
                            font-weight: normal;
                            font-size: 30px;
                            cursor: pointer;
                        }
                    }
                }
            }
            >div {
                >span {
                    >i {
                        color: #00338d;
                    }
                }
            }
            th {
                padding: 8px !important;
            }
            td {
                border-bottom: 2px solid #ddd;
                padding: 15px 8px !important;
            }
            th,
            td {
                width: 15%;
                /*  padding: 8px;*/
                text-align: left;
                border-right: 0;
                border-left: 0;
                &:not(:last-child) {
                    width: 10%;
                }
                >div {
                    display: flex;
                    justify-content: flex-start;
                    >div {
                        display: flex;
                        flex-direction: column;
                    }
                }
                i {
                    vertical-align: middle;
                    cursor: pointer;
                    color: #888888;
                    &.active {
                        transform: rotate(180deg);
                    }
                }
            }
            >div {
                display: flex;
                background-color: #FFFFFF;
                >span {
                    padding: 10px;
                }
                span+div {
                    color: #111111;
                    font-size: 20px;
                    font-weight: bold;
                    flex: 1 1 auto;
                    padding: 10px;
                }
            }
        }
        div[left] {
            width: 50%;
            display: flex;
            align-items: center;
            padding: 10px;
            span[spacer] {
                flex: 1 1 auto;
            }
            input {
                padding: 5px;
                width: 60%;
                border: 0;
                outline: 0;
                &::placeholder {
                    opacity: 0.7;
                    font-size: 16px;
                    color: #999999;
                }
            }
            span {
                i {
                    color: #999999;
                    opacity: 0.8;
                    cursor: pointer;
                }
            }
        }
        div[right] {
            display: flex;
            width: 50%;
            background: #00338d;
            padding: 10px;
            align-items: center;
            span {
                color: #FFF;
            }
            span:first-child {
                margin-right: 10px;
            }
            span:nth-child(2) {
                flex: 1 1 auto;
            }
            span:last-child {
                cursor: pointer;
            }
        }
        >button {
            position: relative;
            left: calc(100% - 231px);
            margin-top: 18px;
        }
    }
    program-registration div[registration-list] table[mode="view"] th {
        span {
            cursor: pointer;
        }
    }
}

admin-programs {
    div[program-setting] {
        width: 100%;
        div[program-list] {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 20px;
            /*   border: 1px solid #ddd;*/
            border-top: 4px solid #00338D;
            div[head] {
                div[right-head] {
                    display: flex;
                    flex-basis: 25%;
                    padding: 10px;
                    align-items: center;
                    i {
                        color: #999999;
                        font-weight: normal;
                        font-size: 30px;
                    }
                    form[search] {
                        flex: 6;
                        input {
                            padding: 5px 10px;
                            width: 94%;
                            /* flex: 1 1 auto; */
                            border: 0;
                            color: #999999;
                            font-size: 16px;
                            opacity: 0.7;
                        }
                    }
                    form[search]+span {
                        flex: 1;
                    }
                    form[search]+span+span {
                        flex: 1;
                    }
                }
                div[close] {
                    padding: 10px;
                    border-left: 2px solid #ddd;
                    span {
                        i {
                            color: #00338d;
                            font-weight: normal;
                            font-size: 30px;
                            cursor: pointer;
                        }
                    }
                    &.undo {
                        // width: 20%;
                        display: flex;
                        align-items: center;
                        // flex: 0;
                        button {
                            flex: 1;
                            font-size: 14px;
                            color: #00338D;
                            letter-spacing: 0;
                            background: transparent;
                            border: 0;
                            cursor: pointer;
                            outline: 0;
                        }
                        span[save] {
                            flex: 2;
                            font-size: 14px;
                            color: rgba(0, 154, 68, 0.99);
                            letter-spacing: 0;
                            font-weight: bold;
                        }
                        span[save]+span {
                            flex: 1;
                            i {
                                color: rgba(0, 154, 68, 0.99);
                            }
                        }
                    }
                }
            }
            >div {
                display: flex;
                background-color: #FFFFFF;
                align-items: center;
                >span {
                    padding: 18px 15px 18px 20px;
                    >i {
                        color: #00338d;
                    }
                }
                span+div {
                    color: #111111;
                    font-size: 20px;
                    font-weight: bold;
                    flex: 1 1 auto;
                    padding: 10px;
                }
            }
            div[mode="open"] {
                height: 70px;
                div[left],
                div[right] {
                    display: none;
                }
            }
            div[mode="close"] {
                height: 70px;
                div[left],
                div[right] {
                    display: flex;
                    span {
                        i {
                            display: flex;
                        }
                    }
                }
                div[left] {
                    flex: 1;
                }
                div[right] {
                    position: relative;
                    display: flex;
                    width: 50%;
                    background: #00338d;
                    padding: 10px 0;
                    align-items: center;
                    height: 70px;
                    border-left: solid 4px #f5f5f5;
                    >span {
                        color: #FFF;
                        &:first-child {
                            margin-left: 20px;
                            margin-right: 10px;
                        }
                        &:last-child {
                            cursor: pointer;
                            margin-right: 10px;
                        }
                        &:nth-child(2) {
                            flex: 1 1 auto;
                            font-size: 20px;
                            font-weight: bold;
                        }
                    }
                    +span {
                        display: none;
                    }
                    section[cancel-msg] {
                        &:after {
                            content: '';
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            width: 0;
                            height: 0;
                            border-top: solid 14px #ffffff;
                            border-left: solid 14px transparent;
                            border-right: solid 14px transparent;
                        }
                        position: absolute;
                        width: 235px;
                        height: 100px;
                        top: -120px;
                        right: 10px;
                        background: #FFFFFF;
                        box-shadow: 0 2px 5px 0 rgba(0,
                        0,
                        0,
                        0.26),
                        0 2px 10px 0 rgba(0,
                        0,
                        0,
                        0.16);
                        border-radius: 2px;
                        padding: 10px;
                        display: flex;
                        z-index:999;
                        >div {
                            justify-content: space-around;
                            &:first-child {
                                display: flex;
                                flex-direction: column;
                                span:first-child {
                                    font-size: 16px;
                                    color: #111111;
                                    letter-spacing: 0;
                                    text-align: center;
                                    line-height: 22px;
                                    font-weight: bold;
                                }
                                article {
                                    display: flex;
                                    justify-content: center;
                                    button {
                                        &:first-child {
                                            background: transparent;
                                            color: #00338D;
                                            outline: 0;
                                            cursor: pointer;
                                            font-size: 14px;
                                            border: 0;
                                            padding: 4px;
                                            margin-right: 4px;
                                        }
                                        &:nth-child(2) {
                                            color: #FFF;
                                            background: #00338D;
                                            font-size: 14px;
                                            border: 0;
                                            padding: 4px;
                                            margin-right: 4px;
                                            width: 130px;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    figure {
                        display: flex;
                        right: 10px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 999;
                        margin: 0;
                        button[save] {
                            color: #00338D;
                            font-size: 14px;
                            border: 0;
                            padding: 4px;
                            margin-right: 4px;
                            width: 82px;
                            cursor: pointer;
                            justify-content: center;
                        }
                        button[cancel] {
                            background: transparent;
                            color: #fff;
                            outline: 0;
                            cursor: pointer;
                            font-size: 14px;
                            border: 0;
                            padding: 4px;
                            margin-right: 10px;
                        }
                    }
                }
                span {
                    i {
                        display: none;
                    }
                }
                span+div,
                div[close],
                div[right-head] {
                    display: none !important;
                }
            }
            table[mode="view"] {
                .view,
                .last-view {
                    display: none;
                }
                .edit {
                    display: table-cell;
                    >div {
                        span {
                            display: flex;
                            align-items: center;
                            i {
                                color: #00338d;
                            }
                        }
                    }
                }
                tr {
                    &:hover {
                        // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13), 0 0 10px 0 rgba(0, 0, 0, 0.05);
                    }
                }
            }
            th,
            td {
                width: 13%;
                padding: 13px 10px 13px 20px;
                text-align: left;
                border-right: 0;
                border-left: 0;
                >div {
                    display: flex;
                    justify-content: flex-start;
                    >div {
                        display: flex;
                        flex-direction: column;
                        &:nth-child(2) {
                            margin-left: 30px;
                        }
                    }
                }
                i {
                    vertical-align: middle;
                    cursor: pointer;
                    color: #888888;
                    &.active {
                        transform: rotate(180deg);
                    }
                }
                img {
                    margin-left: 7px;
                    &.active {
                        transform: rotate(180deg);
                    }
                }
            }
            th {
                span {
                    font-size: 13px;
                    color: #888888;
                    text-transform: uppercase;
                }
            }
            td {
                border-bottom: 0px solid #ddd;
                &:first-child>div {
                    display: flex;
                    flex-direction: column;
                    max-width: 17vw;
                    box-sizing: border-box;
                    span:first-child {
                        color: #00338D;
                        font-weight: bold;
                        margin-top: 10px;
                        a {
                            text-decoration: none;
                            color: #00338D;
                        }
                    }
                    span:nth-child(2) {
                        font-size: 14px;
                        margin-top: 10PX;
                        margin-bottom: 10PX;
                        color: #888888;
                    }
                }
                &:nth-child(2)>div {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    box-sizing: border-box;
                    span:first-child {
                        color: rgba(17, 17, 17, 0.7);
                        font-size: 16px;
                        font-weight: bold;
                        margin-top: 10px;
                    }
                    span:nth-child(2) {
                        font-size: 14px;
                        margin-top: 10PX;
                        margin-bottom: 10PX;
                        color: #888888;
                    }
                }
                &:nth-child(3),
                &:nth-child(4) {
                    >div {
                        div {
                            span:first-child {
                                color: #111111;
                                font-size: 16px;
                                font-weight: normal;
                            }
                            span:nth-child(2) {
                                font-size: 14px;
                                margin-top: 10PX;
                                margin-bottom: 10PX;
                                color: #888888;
                            }
                        }
                    }
                }
            }
        }
    }
}

.wrapper {
    max-width: 1200px;
    display: flex;
    margin: 0 auto;
    padding: 0 20px;
    .center {
        background-color: #EEEEEF;
        width: 100%;
        height: calc(100vh - 10px);
        display: flex;
        flex-direction: column;
        figure {
            flex: 1;
        }
    }
}

div[class-roster-header] {
    display: flex;
    flex-direction: column;
    padding: 0 20px 0 20px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    >div[breadcrumb] {
        display: flex;
        align-items: center;
        margin-top: 20px;
        >span {
            i {
                color: #00338D;
            }
        }
        >p {
            margin: 0 0 0 10px;
            font-size: 14px;
            color: #111111;
            letter-spacing: 0;
            line-height: 25px;
        }
    }
    >div[title] {
        display: flex;
        align-items: center;
        h1 {
            font-size: 32px;
            margin: 25px 0;
            color: #00338D;
            letter-spacing: 0;
        }
        span {
            padding: 6px 4px;
            min-width: 100px;
            // height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            margin-left: 20px;
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 0.15px;
            text-transform: uppercase;
            &.alert {
                background: rgba(246, 141, 46, 0.08);
                color: #F68D2E;
            }
            &.active {
                background: rgba(0, 163, 161, 0.08);
                color: #00A3A1;
            }
            &.gray {
                background: rgba(51, 51, 51, 0.08);
                color: #333333;
            }
        }
    }
    >div[items] {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        >div {
            border-left: solid 1.5px #e5e5e5;
            padding: 0 25px;
            min-height: 45px;
            &:first-child {
                padding-left: 0;
                border-left: none;
            }
            &[place] {
                display: flex;
                align-items: center;
            }
        }
        >div[content] {
            >p {
                color: #00338D;
                font-size: 14px;
                color: #00338D;
                letter-spacing: 0;
                font-weight: bold;
                margin: 0;
                span {
                    color: #111111;
                    display: block;
                    margin-top: 8px;
                    font-weight: normal;
                    font-size: 16px;
                }
            }
        }
        >div[place] {
            >span {
                display: flex;
                align-items: center;
                i {
                    color: #00338D;
                    margin-right: 10px;
                }
            }
        }
        >div[date] {
            position: relative;
            padding-right: 50px;
            &::after {
                content: '';
                border: 5px solid transparent;
                border-top-color: #00338D;
                position: absolute;
                top: 10px;
                right: 25px;
                transform: translateY(-50%);
            }
            >div {
                display: flex;
                flex-direction: row;
                align-items: center;
                max-width: 140px;
                position: relative !important;
                >span {
                    i {
                        color: #00338D;
                    }
                }
                >div {
                    margin-left: 10px;
                    >span:first-child {
                        margin: 0 0 8px 0;
                        display: flex;
                        font-size: 16px;
                        color: #111111;
                        letter-spacing: 0;
                    }
                    >span:nth-child(2) {
                        font-size: 14px;
                        color: #888888;
                        letter-spacing: 0;
                    }
                }
            }
        }
        >div[time] {
            >div {
                display: flex;
                flex-direction: row;
                align-items: center;
                max-width: 140px;
                >span {
                    i {
                        color: #00338D;
                    }
                }
                >div {
                    margin-left: 10px;
                    >span:first-child {
                        margin: 0 0 8px 0;
                        display: flex;
                        font-size: 16px;
                        color: #111111;
                        letter-spacing: 0;
                    }
                    >span:nth-child(2) {
                        letter-spacing: 0;
                    }
                }
            }
        }
    }
}

div[class-roster-title] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 34px 25px 11px;
    h1 {
        margin: 0;
        font-size: 32px;
        font-weight: bold;
    }
    .export-button {
        padding: 15px 20px;
        border: none;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        background: #00338D;
        list-style: none;
        position: relative;
        justify-content: center;
        cursor: pointer;
        ul[export-dd] {
            margin: 0;
            position: absolute;
            background: #00338D;
            color: #ffffff;
            border-top: solid 1.5px #ffffff;
            padding: 0;
            font-size: 16px;
            font-weight: normal;
            top: 100%;
            left: 0;
            z-index: 9;
            width: 100%;
            &[show="false"] {
                display: none;
            }
            >li {
                padding: 15px 0 15px 20px;
                cursor: pointer;
                &:not(:last-child) {
                    border-bottom: solid 1.5px rgba(25, 255, 255, 0.2);
                }
            }
        }
    }
}

div[class-roster] {
    .tab-body {
        position: relative;
    }
    .expand {
        i {
            color: #00338D !important;
            cursor: pointer;
        }
    }
    .panel {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 20px;
        // border: 1px solid #ddd;
        border-top: 4px solid #00338D;
        background: #ffffff;
        .panel-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            .panel-title {
                display: flex;
                align-items: center;
                >span {
                    padding: 10px;
                    i {
                        color: #00338D;
                    }
                }
                >p {
                    color: #111111;
                    font-size: 20px;
                    font-weight: bold;
                    padding: 10px;
                    margin: 0;
                }
            }
            .panel-search {
                display: flex;
                width: 350px;
                margin-right: 10px;
                i {
                    color: #999999;
                    font-weight: normal;
                    font-size: 30px;
                    cursor: pointer;
                }
                form[search] {
                    flex: 1;
                    input {
                        padding: 5px 10px;
                        border: 0;
                        color: #999999;
                        font-size: 16px;
                        opacity: 0.7;
                        outline: 0;
                        width: 92%;
                    }
                }
            }
        }
    }
    .facilitator-table,
    .participant-table {
        tr {
            >td {
                padding-top: 0;
                padding-bottom: 0;
                >div {
                    >span {
                        padding: 18px 0px !important;
                    }
                    .expand {
                        >img {
                            position: relative;
                            top: 8px;
                        }
                    }
                }
            }
        }
        tr[edit="false"] {
            >td {
                >div[timeexpanded] {
                    display: none !important;
                }
                >div[timeexpanded] {
                    .time-picker {
                        display: none;
                    }
                }
            }
        }
        tr.reason-row {
            &[edit="false"] {
                display: none !important;
            }
            &[edit="true"] {
                >td {
                    border-bottom: 2px solid #ddd !important;
                }
            }
            .reason {
                display: flex;
                flex-direction: column;
                .title {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    >i {
                        margin-right: 10px;
                        color: #888888;
                    }
                    >p {
                        margin: 0;
                        color: #111111;
                        font-weight: bold;
                        span {
                            font-weight: normal;
                            margin-left: 10px;
                        }
                    }
                }
                .add-reason {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-top: 15px;
                    margin-bottom: 15px;
                    position: relative;
                    >span {
                        color: #00338D;
                        font-weight: bold;
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        margin-left: 30px;
                        >span[required] {
                            margin-left: 5px;
                            display: none;
                        }
                        i {
                            margin-right: 10px;
                            font-size: 20px;
                        }
                    }
                    &[invalid="true"] {
                        >span {
                            color: #BC204B;
                            >span[required] {
                                display: inline;
                            }
                        }
                    }
                }
                div[reason-menu] {
                    position: absolute;
                    background: #FFFFFF;
                    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
                    border-radius: 2px;
                    padding: 13px;
                    max-width: 272px;
                    z-index: 9;
                    top: 100%;
                    left: 30px;
                    div[reason-menu-item] {
                        height: 35px;
                        display: flex;
                        align-items: center;
                    }
                    &[show="false"] {
                        display: none;
                    }
                }
            }
        }
        td:last-child {
            min-width: 165px;
            max-width: 165px;
        }
        .time-item {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between !important;
            >span {
                >span {
                    color: #00338D;
                    margin-left: 5px;
                    font-weight: bold;
                }
            }
        }
        div[timeexpanded] {
            display: flex;
            flex-direction: column;
            .total {
                flex-direction: row;
                margin-bottom: 27px;
                span {
                    margin-right: 7px;
                    &:nth-child(2) {
                        color: #00338d;
                        font-weight: bold;
                    }
                }
            }
            .time-row {
                margin-bottom: 18px;
                >div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &:nth-child(1) {
                        position: relative;
                        >span {
                            &:nth-child(2),
                            &:nth-child(4) {
                                min-width: 24px;
                                max-width: 24px;
                                text-align: center;
                            }
                            &.select-view {
                                font-size: 16px !important;
                                color: #111111 !important;
                                border-bottom: solid 1px rgba(153, 153, 153, 0.30);
                                &[blank] {
                                    color: #888888 !important;
                                }
                                &:after {
                                    content: '';
                                    position: absolute;
                                    top: 50%;
                                    left: 100%;
                                    margin-left: -10px;
                                    transform: translateY(calc(-50% - 5px));
                                    width: 0;
                                    height: 0;
                                }
                                &.w-100 {
                                    width: 100%;
                                    padding-right: 0;
                                }
                            }
                        }
                        >time-picker {
                            position: absolute;
                            top: 30px;
                            &:nth-of-type(2) {
                                right: 100%;
                            }
                        }
                    }
                    &:nth-child(2) {
                        justify-content: flex-start;
                        .time-highlight {
                            color: #00338d !important;
                            font-size: 14px;
                            font-weight: bold;
                            display: flex;
                            align-items: center;
                            i {
                                margin-right: 5px;
                                font-size: 21px;
                            }
                        }
                        .comment {
                            width: calc(50% + 10px);
                            >span {
                                position: relative;
                                top: 3px;
                            }
                        }
                    }
                }
                &[mode="hide"] {
                    display: none !important;
                }
                div[close] {
                    i {
                        font-size: 18px;
                        color: #888888;
                    }
                }
            }
        }
    }
    .facilitator-table {
        td {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .custBtn1 {
        background: #ff0000 !important;
    }
    .custBtn2 {
        background: #000000 !important;
    }
     ::ng-deep .mat-menu-content:not(:empty) {
        padding: 0 !important;
    }
    .popover {
        span {
            width: 235px;
        }
    }
}

div[report-arrival] {
    padding: 30px 25px;
    .user-profile {
        display: flex;
        justify-content: initial !important;
        >img {
            margin-right: 10px;
            align-self: center;
        }
        span {
            &[name] {
                color: #00338D;
                font-weight: bold;
                margin-bottom: 8px;
            }
            &[email] {
                font-size: 14px;
                color: #111111;
            }
        }
    }
    .table {
        tbody td,
        thead th {
            vertical-align: middle;
            border-bottom-width: 5px;
            border-color: #f5f5f5;
            padding-left: 20px;
        }
        tbody td {
            padding: 20px 10px;
            padding-left: 20px;
        }
        thead.sticky {
            th {
                position: sticky;
                top: 0;
                background: #ffffff;
            }
        }
    }
    .panel {
        margin: 0;
    }
    div[flight-report-header] {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        align-items: center;
        h3 {
            font-size: 32px;
            color: #111111;
            font-weight: bold;
            margin: 0;
        }
    }
}

div[report-departure] {
    padding: 30px 25px;
    .user-profile {
        display: flex;
        justify-content: initial !important;
        >img {
            margin-right: 10px;
            align-self: center;
        }
        span {
            &[name] {
                color: #00338D;
                font-weight: bold;
                margin-bottom: 8px;
            }
            &[email] {
                font-size: 14px;
                color: #111111;
            }
        }
    }
    .table {
        tbody td,
        thead th {
            vertical-align: middle;
            border-bottom-width: 5px;
            border-color: #f5f5f5;
            padding-left: 20px;
        }
        tbody td {
            padding: 20px 10px;
            padding-left: 20px;
        }
        thead.sticky {
            th {
                position: sticky;
                top: 0;
                background: #ffffff;
            }
        }
    }
    .panel {
        margin: 0;
    }
    div[flight-report-header] {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        align-items: center;
        h3 {
            font-size: 32px;
            color: #111111;
            font-weight: bold;
            margin: 0;
        }
    }
}

div[members-popup] {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    display: flex;
    flex-direction: column;
    min-height: 550px;
    background: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    border-radius: 2px;
    display: flex;
    justify-content: flex-start;
    width: 550px;
    >div[top] {
        display: flex;
        width: 96%;
        padding: 10px;
        border-bottom: 1.5px solid #ddd;
        div:first-child {
            display: flex;
            flex: 1;
            align-items: center;
            span:first-child {
                font-size: 20px;
                color: #111111;
                letter-spacing: 0;
            }
        }
        div:last-child {
            display: flex;
            flex: 1 1 auto;
            form {
                width: 100%;
                input {
                    border: 0;
                    outline: 0;
                    width: 80%;
                    font-size: 14px;
                    color: #999999;
                    letter-spacing: 0;
                    opacity: 0.7;
                }
            }
            form+span {
                display: flex;
                align-items: center;
                i {
                    color: #999999;
                    font-size: 22px;
                    cursor: pointer;
                }
            }
            >div {
                display: flex;
            }
        }
    }
    div[list-parent] {
        display: flex;
        flex-direction: column;
        max-height: 370px;
        overflow-y: auto;
        div[list] {
            display: flex;
            padding: 10px;
            align-items: center;
            justify-content: space-between;
            /*  background: rgba(0, 51, 141, 0.04);*/
            cursor: pointer;
            &.active {
                background: rgba(0, 51, 141, 0.04);
                >div {
                    &:first-child {
                        width: 7%;
                    }
                    &:nth-child(2) {
                        width: 88%;
                        span:nth-child(2) {
                            color: #111111;
                        }
                    }
                    &:nth-child(3) {
                        width: 5%;
                    }
                }
            }
            >div {
                &:first-child {
                    width: 7%;
                }
                &:nth-child(2) {
                    width: 88%;
                    display: flex;
                    flex-direction: column;
                    span:first-child {
                        font-size: 16px;
                        color: #00338d;
                        letter-spacing: 0;
                        font-weight: bold;
                    }
                    span:nth-child(2) {
                        font-size: 14px;
                        color: #888888;
                        letter-spacing: 0;
                    }
                }
                &:nth-child(3) {
                    width: 5%;
                    span {
                        i {
                            color: #00338d;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
    >div[space] {
        flex-grow: 1;
    }
    >div[bottom] {
        .chips {
            display: flex;
            align-items: center;
            border-top: 1.5px solid #ddd;
            padding: 10px;
            justify-content: flex-start;
            overflow-y: auto;
            height: 90px;
            div[chips] {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                mat-chip-list {
                    margin-bottom: 5px;
                }
            }
            mat-chip-list span:first-child {
                font-size: 12px;
                color: #00338d;
                background: transparent;
                width: 105px;
            }
            mat-chip-list span:nth-child(2) {
                min-width: 7px;
                font-size: 10px;
                font-weight: 700;
                /* height: 15px; */
                background-color: #00338d;
                padding: 3px 5px;
                border-radius: 50%;
                /* display: inline; */
                color: #fff;
                margin-left: 10px;
                cursor: pointer;
            }
            span:first-child {
                i {
                    color: #00338d;
                }
            }
            span:nth-child(2) {
                font-size: 12px;
                color: #00338d;
                font-weight: bold;
            }
            span[select] {
                width: 80px;
            }
            .mat-chip.mat-standard-chip {
                color: rgb(0, 51, 141);
            }
            span:last-child {
                margin-left: 10px;
                color: #fff;
            }
        }
        .mat-chip.mat-standard-chip {
            background-color: rgba(0, 51, 141, 0.04);
        }
        .last-row {
            display: flex;
            background-color: #fff;
            width: 100%;
            margin: 0 auto;
            padding: 20px 10px;
            box-sizing: border-box;
            border-top: 1.5px solid #ddd;
            input:first-child {
                display: none;
            }
            input:last-child {
                background: #00338d;
                border: 0;
                color: #fff;
                margin-left: 15px;
                min-width: 84px;
                cursor: pointer;
                height: 27px;
            }
            input:nth-child(3) {
                background: transparent;
                border: 0;
                color: #00338d;
                cursor: pointer;
                outline: 0;
                padding: 5px;
            }
            span {
                flex: 1 1 auto;
            }
        }
    }
}

div[mode="false"] {
    display: none;
}

.date-dropdown {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    border-radius: 2px;
    z-index: 9;
    min-width: calc(150px + 50px);
    width: 100%;
    margin-left: 0 !important;
    max-height: 330px;
    overflow: auto;
    &[show="false"] {
        display: none;
    }
    div[dd-item] {
        padding: 20px 25px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        position: relative;
        span {
            font-size: 14px;
            margin-top: 8px;
            color: #888888;
        }
        &.active {
            background: rgba(0, 51, 141, 0.05);
            border: none !important;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: calc(100% - 20px);
                margin-left: -10px;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-bottom: solid 5px #00338D;
                border-left: solid 5px transparent;
                border-right: solid 5px transparent;
            }
        }
        &.disable {
            color: #999999;
        }
        &:not(:last-child) {
            border-bottom: 1.5px solid #E5E5E5;
        }
    }
}

.timepicker {
    background: #fff;
    width: max-content;
    position: absolute;
    top: 0;
    z-index: 9;
    left: 0;
    padding: 20px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    border-radius: 2px;
    &[show="false"] {
        display: none;
    }
    .time-header {
        color: #888888;
        font-size: 12px;
        margin-bottom: 15px;
    }
    .time-field {
        width: 80px;
    }
    .time-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        .no-margin {
            width: 80px;
            .mat-form-field-appearance-legacy .mat-form-field-wrapper {
                padding-bottom: 0;
            }
        }
    }
    .time-buttons {
        display: flex;
        flex: 1;
        justify-content: center;
        .btn:first-child {
            margin-right: 10px;
        }
        .btn {
            padding: 5px 20px;
            border: none;
            font-size: 14px !important;
            font-weight: bold;
            display: flex;
            align-items: center;
            background: transparent;
            color: #00338D;
            &.btn-primary {
                background: #00338D !important;
                color: #ffffff;
            }
        }
    }
}

img[ml10] {
    margin-left: 10px;
}

mat-form-field[hideLabel].mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
}

div[cr-popup-body] {
    background: #f5f5f5;
}

tabs[programTab] {
    .mat-tab-label {
        padding: 0 !important;
        min-width: auto !important;
        margin-right: 35px !important;
    }
    .mat-tab-group {
        margin-left: 20px;
    }
}

program-external-classes {
    width: 100%;
    div[Class-list] {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 20px;
        border-top: 4px solid #00338D;
        div[mode="open"] {
            div[close].undo {
                width: 20%;
                display: flex;
                align-items: center;
                flex: 0;
            }
            div[close].undo span[save] {
                flex: 2;
                font-size: 14px;
                color: rgba(0, 154, 68, 0.99);
                letter-spacing: 0;
                font-weight: bold;
            }
            div[close].undo span[save]+span i {
                color: rgba(0, 154, 68, 0.99);
            }
            div[left],
            div[right] {
                display: none;
            }
        }
        div[mode="close"] {
            height: 70px;
            div[left],
            div[right] {
                display: flex;
                height: 50px;
                span {
                    i {
                        display: flex;
                        cursor: pointer;
                    }
                }
            }
            div[right]+span {
                display: none;
            }
            span+div {
                display: none !important;
            }
            span {
                i {
                    display: none;
                }
            }
            div[close] {
                display: none !important;
            }
            div[right-head] {
                display: none !important;
            }
        }
        div[head] {
            div[right-head] {
                display: flex;
                flex-basis: 25%;
                padding: 10px;
                i {
                    color: #999999;
                    font-weight: normal;
                    font-size: 30px;
                }
                form[search] {
                    flex: 6;
                    input {
                        padding: 5px 10px;
                        width: 94%;
                        border: 0;
                        color: #999999;
                        font-size: 16px;
                        opacity: 0.7;
                        outline: 0;
                    }
                }
                form[search]+span {
                    flex: 1;
                }
                form[search]+span+span {
                    flex: 1;
                }
            }
            div[close] {
                padding: 10px;
                border-left: 2px solid #ddd;
                span {
                    i {
                        color: #00338d;
                        font-weight: normal;
                        font-size: 30px;
                        cursor: pointer;
                    }
                }
            }
        }
        div[opne="false"] {
            display: none;
        }
        div[opne="true"] {
            flex-direction: column;
            th,
            td {
                width: 50%;
            }
        }
        >div {
            display: flex;
            background-color: #FFFFFF;
            height: 70px;
            align-items: center;
            >span {
                padding: 10px 10px 10px 18px;
                >i {
                    color: #00338d;
                }
            }
            span+div {
                color: #111111;
                font-size: 20px;
                font-weight: bold;
                flex: 1 1 auto;
                padding: 10px;
            }
        }
        div[left] {
            flex: 1;
        }
        div[right] {
            display: flex;
            width: 50%;
            background: #00338d;
            padding: 10px 0px;
            align-items: center;
            span {
                color: #FFF;
                display: flex;
                font-size: 20px;
                font-weight: bold;
                &.disable {
                    display: none;
                }
                button {
                    margin-right: 10px;
                    margin-right: 10px;
                    font-size: 13px !important;
                    &.disable {
                        display: none;
                    }
                    &:nth-of-type(1) {
                        background: transparent;
                        color: #fff;
                        outline: 0;
                        cursor: pointer;
                        font-size: 14px;
                        border: 0;
                        padding: 4px;
                        margin-right: 10px;
                    }
                    &:nth-of-type(2) {
                        color: #00338D;
                        font-size: 14px;
                        border: 0;
                        padding: 4px;
                        margin-right: 4px;
                        width: 82px;
                        cursor: pointer;
                        justify-content: center;
                    }
                }
                &:first-child {
                    margin-left: 20px;
                    margin-right: 10px;
                }
                &:nth-child(2) {
                    flex: 1 1 auto;
                }
                &:nth-child(3),
                &:last-child {
                    cursor: pointer;
                    margin-right: 10px;
                }
            }
        }
        table[mode="view"] {
            .last-view {
                display: none;
            }
            .edit {
                display: table-cell;
                >div {
                    span {
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        i {
                            color: #00338d;
                        }
                    }
                }
            }
        }
        th,
        td {
            width: 13%;
            padding: 10px 10px 10px 20px;
            text-align: left;
            border-right: 0;
            border-left: 0;
            >div {
                display: flex;
                justify-content: flex-start;
                >div {
                    display: flex;
                    flex-direction: column;
                }
            }
            i {
                vertical-align: middle;
                cursor: pointer;
                color: #888888;
                &.active {
                    transform: rotate(180deg);
                }
            }
            img {
                vertical-align: middle;
                cursor: pointer;
                margin-left: 7px;
                &.active {
                    transform: rotate(180deg);
                }
            }
        }
        th {
            span {
                font-size: 13px;
                color: #888888;
                text-transform: uppercase;
            }
        }
        td {
            &.red {
                border-left: 3px solid #F68D2E;
                span.alert {
                    padding: 0 10px;
                    min-width: 100px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba(51, 51, 51, 0.08);
                    border-radius: 2px;
                    color: #F68D2E;
                    font-size: 13px;
                    font-weight: bold;
                    letter-spacing: 0.15px;
                }
            }
            &.green {
                border-left: 3px solid #00A3A1;
                span.active {
                    padding: 0 10px;
                    min-width: 100px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba(51, 51, 51, 0.08);
                    border-radius: 2px;
                    color: #00A3A1;
                    font-size: 13px;
                    font-weight: bold;
                    letter-spacing: 0.15px;
                }
            }
            &.disable {
                border-left: 3px solid #333333;
            }
            &:first-child {
                >div {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    box-sizing: border-box;
                    justify-content: flex-start;
                    span:first-child {
                        margin-right: 0;
                    }
                    span:nth-child(2) {
                        padding: 0 10px;
                        min-width: 100px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 2px;
                        font-size: 13px;
                        font-weight: bold;
                        letter-spacing: 0.15px;
                        &.alert {
                            background: rgba(246, 141, 46, 0.08);
                            color: #F68D2E;
                        }
                        &.active {
                            background: rgba(0, 163, 161, 0.08);
                            color: #00A3A1;
                        }
                        &.gray {
                            background: rgba(51, 51, 51, 0.08);
                            color: #333333;
                        }
                    }
                }
            }&:nth-child(2)>div {
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: 1vw;
                margin-right: 0;
                opacity:1;
                // white-space: nowrap;
                align-items: center;
                span:first-child {
                margin-top: 4px;
                vertical-align: text-bottom;
                margin-bottom: 8px;
                }
            }
           &:nth-child(2)
                >div {
                    span:first-child {
                         display: flex;
                        align-items: center;
                    }
                }
            &:nth-child(3)>div {
                display: flex;
                flex-direction: column;
                width: 100%;
                box-sizing: border-box;
                max-width: 14vw;
                span:first-child {
                    font-size: 16px;
                    font-weight: bold;
                    color: #00338D;
                    cursor: pointer;
                }
                span:nth-child(4) {
                    font-size: 14px;
                    margin-top: 10PX;
                    margin-bottom: 10PX;
                    color: #888888;
                }
            }
            &:nth-child(5) {
                >div {
                    width: 70%;
                    div {
                        span:first-child {
                            color: #111111;
                            font-size: 16px;
                            font-weight: normal;
                        }
                        span:nth-child(2) {
                            font-size: 14px;
                            margin-top: 10PX;
                            margin-bottom: 10PX;
                            color: #888888;
                        }
                    }
                }
            }
            &:nth-child(6) {
                >div {
                    span:first-child {
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        i {
                            color: #00338d;
                            ;
                        }
                    }
                }
                span+div {
                    span:first-child {
                        margin-bottom: 5px;
                    }
                    span:last-child {
                        font-size: 14px;
                        color: #888888;
                    }
                }
            }
            &:nth-child(7) {
                >div {
                    span:first-child {
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        i {
                            color: #00338d;
                            ;
                        }
                    }
                }
                span+div {
                    span:first-child {
                        margin-bottom: 5px;
                    }
                    span:last-child {
                        font-size: 14px;
                        color: #888888;
                    }
                }
            }
            &:nth-child(6) {
                >div {
                    span:first-child {
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        i {
                            color: #00338d;
                            ;
                        }
                    }
                }
                span+div {
                    span:first-child {
                        margin-bottom: 5px;
                    }
                    span:last-child {
                        font-size: 14px;
                        color: #888888;
                    }
                }
            }
            &:last-child {
                >div {
                    div:first-child {
                        justify-content: space-between;
                        span {
                            font-size: 14px;
                            color: #888888;
                            margin-right: 10px;
                        }
                    }
                    div:nth-child(2) {
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}

div[programs-external] {
    margin: 25px;
    div[pe-card] {
        padding: 25px;
        background: #fff;
        margin-bottom: 25px;
        h3[pe-title] {
            font-size: 32px;
            margin: 0;
        }
        span[pe-sub-title] {
            display: flex;
            align-items: center;
            margin-top: 8px;
            font-size: 14px;
            >img {
                margin-right: 5px;
            }
        }
        span[pe-divider] {
            display: block;
            margin: 16px 0 20px 0;
            border-bottom: 2px solid #e5e5e5;
        }
        div[pe-list] {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
            >p {
                font-size: 16px;
                color: #00338D;
                font-weight: bold;
                margin-bottom: 8px;
                cursor: pointer;
            }
            >span {
                font-size: 14px;
                color: #888888;
            }
        }
    }
}

div[Class-list] table[tblock] {
    display: block;
    overflow-x: auto;
}

div[cancel-widget] {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(33, 45, 58, 0.50);
    display: flex;
    align-items: center;
    justify-content: center;
    &[showCancelWidget="false"] {
        display: none;
    }
    >div {
        justify-content: space-around;
        width: 265px;
        background: #FFFFFF;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
        border-radius: 2px;
        padding: 15px 20px;
        display: flex;
        box-sizing: border-box;
        &:first-child {
            display: flex;
            flex-direction: column;
            span:first-child {
                font-size: 16px;
                color: #111111;
                letter-spacing: 0;
                text-align: center;
                line-height: 22px;
                font-weight: bold;
            }
            article {
                display: flex;
                justify-content: space-around;
                margin-top: 15px;
                button {
                    align-items: center;
                    justify-content: center;
                    font-weight: normal;
                    border: 0;
                    padding: 6px 0;
                    outline: 0;
                    font-size: 14px;
                    cursor: pointer;
                    &:first-child {
                        background: transparent;
                        color: #00338D;
                        margin-right: 10px;
                    }
                    &:nth-child(2) {
                        color: #FFF;
                        background: #00338D;
                        padding: 6px 25px;
                    }
                }
            }
        }
    }
}
div[Class-list] table[tblock] {
    display: table;
}



.jp-tooltip{
  opacity: 0;
  .jp-arrow{
    position: relative;
    background: white;
    width: 10px;
    height: 10px;
    top: -30px;
    left: 50%;
    z-index: 10;
    float: left;
    right: 50%;
    margin: auto;
    justify-content: center;
    transform: rotate(45deg);
    border-left: 1px solid rgba(0,0,0,.2);
    border-top: 1px solid rgba(0,0,0,.2);
  }
  .jp-overdue{
    color:#BC204B;
  }
  .jp-pending{
    color:#EAAA00;
  }
  .jp-confirmed{
    color:#009A44;
  }
}
.jp-tooltip:hover{
  display:initial;
}
.jp-timer{
  position: relative !important;
  display: block !important;
  width: 10px;
  height: 10px;
}

.jp-timer:hover{
  top:20px;
}

.jp-timer:hover+span{
  opacity: 1;
  -webkit-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.59);
  -moz-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.59);
  box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.59);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 3px;
  padding:5px;
  background-color: #fff;
  z-index: 99;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  margin-right: 0 !important;
  position: relative;
  margin-top: 0px;
  top: 40px;
}

.jp-bold{
  font-weight: bold !important;
}

input[type="checkbox"]{
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: white;
    height: 18px;
    width: 18px;
    border: 1px solid lightgrey;
    &:checked {
        background: darkblue;
    }
    &:after {
        content: '';
        position: relative;
        left: 40%;
        top: 20%;
        width: 15%;
        height: 40%;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        display: none;
    }
    &:checked:after {
        display: block;
    }
}
.table-fixedheader {
    > thead {
        position: sticky;
        top: 0;
    }
}
.participant-time-picker {
    right: 260px;
    top: 30px;
}

.time {
    margin-left: 40%;
}
.participant-time {
    margin-left: 70px;
}

.participant-endtime {
    margin-right: 60px;
}

app-program-documents{
        width: 100%;
        div[Class-list] {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 20px;
            border: 1px solid #ddd;
            border-top: 4px solid #00338D;
            div[mode="open"] {
                height: 50px;
                div[left],
                div[right] {
                    display: none;
                }
            }
            div[mode="close"] {
                height: 50px;
                div[left],
                div[right] {
                    display: flex;
                    span {
                        i {
                            display: flex;
                            cursor: pointer;
                        }
                    }
                }
                div[right]+span {
                    display: none;
                }
                span+div {
                    display: none !important;
                }
                span {
                    i {
                        display: none;
                    }
                }
                div[close] {
                    display: none !important;
                }
                div[right-head] {
                    display: none !important;
                }
            }
            table[mode="view"] {
                tr {
                    &:hover {
                        // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13), 0 0 10px 0 rgba(0, 0, 0, 0.05);
                    }
                }
                th {
                    &.edit {
                        span {
                            font-size: 13px;
                            color: #888888;
                            text-transform: uppercase;
                        }
                    }
                }
                .edit {
                    display: table-cell;
                    >div {
                        span {
                            margin-right: 10px;
                            display: flex;
                            align-items: center;
                            i {
                                color: #00338d;
                            }
                        }
                    }
                }
                .default {
                    display: none;
                }
                .view {
                    display: none;
                }
                .last-view {
                    display: none;
                }
            }
            td {
                &.red {
                    border-left: 3px solid #F68D2E;
                    span.alert {
                        padding: 0 10px;
                        min-width: 100px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(51, 51, 51, 0.08);
                        border-radius: 2px;
                        color: #F68D2E;
                        font-size: 13px;
                        font-weight: bold;
                        letter-spacing: 0.15px;
                    }
                }
                &.green {
                    border-left: 3px solid #00A3A1;
                    span.active {
                        padding: 0 10px;
                        min-width: 100px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(51, 51, 51, 0.08);
                        border-radius: 2px;
                        color: #00A3A1;
                        font-size: 13px;
                        font-weight: bold;
                        letter-spacing: 0.15px;
                    }
                }
                &.disable {
                    border-left: 3px solid #333333;
                }
            }
            div[head] {
                div[right-head] {
                    display: flex;
                    flex-basis: 25%;
                    padding: 10px;
                    i {
                        color: #999999;
                        font-weight: normal;
                        font-size: 30px;
                        cursor: pointer;
                    }
                    form[search] {
                        flex: 6;
                        input {
                            padding: 5px 10px;
                            width: 94%;
                            /* flex: 1 1 auto; */
                            border: 0;
                            color: #999999;
                            font-size: 16px;
                            opacity: 0.7;
                            outline: 0;
                            &::placeholder {
                                opacity: 0.7;
                                font-size: 16px;
                                color: #999999;
                            }
                        }
                    }
                    form[search]+span {
                        flex: 1;
                    }
                    form[search]+span+span {
                        flex: 1;
                    }
                }
                div[close] {
                    padding: 10px;
                    border-left: 2px solid #ddd;
                    span {
                        i {
                            color: #00338d;
                            font-weight: normal;
                            font-size: 30px;
                            cursor: pointer;
                        }
                    }
                }
            }
            div[opne="false"] {
                display: none;
            }
            div[opne="true"] {
                flex-direction: column;
                th,
                td {
                    width: 50%;
                }
            }
            >div {
                >span {
                    >i {
                        color: #00338d;
                    }
                }
            }
            td {
                border-bottom: 2px solid #ddd;
                &.red {
                    border-left: 3px solid #F68D2E;
                }
            }
            td:first-child {
                >div {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    box-sizing: border-box;
                    justify-content: flex-start;
                    span:nth-child(2) {
                        padding: 0 10px;
                        min-width: 100px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 2px;
                        font-size: 13px;
                        font-weight: bold;
                        letter-spacing: 0.15px;
                        &.alert {
                            background: rgba(246, 141, 46, 0.08);
                            color: #F68D2E;
                            text-transform: uppercase;
                        }
                        &.active {
                            background: rgba(0, 163, 161, 0.08);
                            color: #00A3A1;
                            text-transform: uppercase;
                        }
                        &.gray {
                            background: rgba(51, 51, 51, 0.08);
                            color: #333333;
                            text-transform: uppercase;
                        }
                    }
                }
            }
            td:nth-child(2)>div {
                display: flex;
                flex-direction: column;
                max-width: 10vw;
                box-sizing: border-box;
                span:first-child {
                    font-size: 16px;
                    font-weight: bold;
                    color: #00338D;
                }
                span:nth-child(2) {
                    font-size: 14px;
                    margin-top: 10PX;
                    margin-bottom: 10PX;
                    color: #888888;
                }
            }
            td:nth-child(3) {
                >div {
                    width: 70%;
                    div {
                        span:first-child {
                            color: #111111;
                            font-size: 16px;
                            font-weight: normal;
                        }
                        span:nth-child(2) {
                            font-size: 14px;
                            margin-top: 10PX;
                            margin-bottom: 10PX;
                            color: #888888;
                        }
                    }
                }
            }
            td:nth-child(4) {
                >div {
                    span:first-child {
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        i {
                            color: #00338d;
                            ;
                        }
                    }
                }
                span+div {
                    span:first-child {
                        margin-bottom: 5px;
                    }
                    span:last-child {
                        font-size: 14px;
                        color: #888888;
                    }
                }
            }
            td:nth-child(5) {
                >div {
                    span:first-child {
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        i {
                            color: #00338d;
                            ;
                        }
                    }
                }
                span+div {
                    span:first-child {
                        margin-bottom: 5px;
                    }
                    span:last-child {
                        font-size: 14px;
                        color: #888888;
                    }
                }
            }
            td:nth-child(6) {
                >div {
                    span:first-child {
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        i {
                            color: #00338d;
                            ;
                        }
                    }
                }
                span+div {
                    span:first-child {
                        margin-bottom: 5px;
                    }
                    span:last-child {
                        font-size: 14px;
                        color: #888888;
                    }
                }
            }
            td:last-child {
                >div {
                    div:first-child {
                        justify-content: space-between;
                        span {
                            font-size: 14px;
                            color: #888888;
                            margin-right: 10px;
                        }
                    }
                    div:nth-child(2) {
                        justify-content: space-between;
                    }
                }
            }
            th,
            td {
                width: 13%;
                padding: 8px;
                text-align: left;
                border-right: 0;
                border-left: 0;
                >div {
                    display: flex;
                    justify-content: flex-start;
                    >div {
                        display: flex;
                        flex-direction: column;
                    }
                }
                i {
                    vertical-align: middle;
                    // cursor: pointer;
                    color: #888888;
                    &.active {
                        transform: rotate(180deg);
                    }
                }
            }
            >div {
                display: flex;
                background-color: #FFFFFF;
                >span {
                    padding: 10px 10px 10px 18px;
                }
                span+div {
                    color: #111111;
                    font-size: 20px;
                    font-weight: bold;
                    flex: 1 1 auto;
                    padding: 10px;
                }
            }
        }
        div[left] {
            width: 50%;
            display: flex;
            align-items: center;
            padding: 10px;
            span[spacer] {
                flex: 1 1 auto;
            }
            input {
                padding: 5px;
                width: 60%;
                border: 0;
                outline: 0;
                &::placeholder {
                    opacity: 0.7;
                    font-size: 16px;
                    color: #999999;
                }
            }
            span {
                i {
                    color: #999999;
                    opacity: 0.8;
                    cursor: pointer;
                }
            }
        }
        div[right] {
            display: flex;
            width: 50%;
            background: #00338d;
            padding: 10px;
            align-items: center;
            span {
                color: #FFF;
                display: flex;
                font-size: 20px;
                &.disable {
                    display: none;
                }
                button {
                    margin-right: 10px;
                    margin-right: 10px;
                    font-size: 13px !important;
                    &.disable {
                        display: none;
                    }
                    &:nth-of-type(1) {
                        color: white;
                        background: transparent;
                        border: none;
                    }
                    &:nth-of-type(2) {
                        color: #00338d;
                        padding: 5px 27px;
                    }
                }
            }
            span:first-child {
                margin-right: 10px;
            }
            span:nth-child(2) {
                flex: 1 1 auto;
            }
            span:last-child {
                cursor: pointer;
            }
        }
    
}