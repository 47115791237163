// text colors
.text-gray-light {
    color: $gray-light
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.text-shade-gray {
    color: $shade-gray
}

.text-cyan-blue {
    color: $cyan-blue
}

.text-red {
    color: $red
}

.text-green {
    color: $green
}

.text-purple {
    color: $purple
}

// fonts weight
.font-weight-bold {
    font-weight: bold
}


.font-weight-400 {
    font-weight: 400
}

.font-weight-500 {
    font-weight: 500
}

.font-weight-600 {
    font-weight: 600
}

.font-weight-700 {
    font-weight: 700
}

.font-arial {
    font-family: Arial;
}

// fonts size
.font-size-12 {
    font-size: 12px;
}

.font-size-13 {
    font-size: 13px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-17 {
    font-size: 17px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-24 {
    font-size: 24px;
}

.horizontal-line {
    border-top: 1px solid #e5e5e5;
}
.color-white
{
     color: $white;
}
.color-black
{
     color: $black;
}