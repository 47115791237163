body {
    margin: 0;
    overflow: hidden;
    font-family: arial;
    background-color: #F5F5F5;

    a {
        &.disabled {
            color: $gray-light;
            cursor: not-allowed;
            pointer-events: "none";
        }
    }
}

.clickme {
    background-color: #EEEEEE;
    padding: 8px 20px;
    text-decoration:none;
    font-weight:bold;
    border-radius:5px;
    cursor:pointer;
}

table {
    width: 100%;
    border-spacing: 0px 5px;
    // background-color: #E5E5E5;
    background-color: transparent;
    th {
        >img {
            margin-left: 7px;
            &.active {
                transform: rotate(180deg);
            }
        }
    }
    &[sticky] {
        background-color: #fff !important;
        position: sticky;
        top: 0;
        text-transform: uppercase;
        color: #888888;
        font-size: 13px;
        font-weight: bold;
        border: 2px solid #ddd;
        border-left: 0;
        border-right: 0;
        th {
            >i {
                vertical-align: middle;
                cursor: pointer;
                color: #888888;
                &.active {
                    transform: rotate(180deg);
                }
            }
            >img {
                margin-left: 7px;
                &.active {
                    transform: rotate(180deg);
                }
            }
        }
    }
    thead,
    tr {
        background-color: #FFFFFF;
    }
}

// .mat-select-panel-wrap {
//     transform: translate(-185px, 48px) !important;
//     min-width: 195px;
//     max-width: 195px;
// }
portal-login {
    display: block;
    background-image: url("/assets/images/login-bg-new.jpg");
    background-size: cover;
    div[journey-login] {
        /*  background: rgba(0, 0, 255, 0.8);*/
        height: 100vh;
        position: relative;
        div[login-section] {
            position: absolute;
            top: 10%;
            left: 10%;
            opacity: 0.85;
            background-image: linear-gradient(180deg, #00338D 0%, #002E7F 78%);
            min-width: 435px;
            min-height: 575px;
            span[text] {
                font-size: 16px;
                color: #FFFFFF;
                display: block;
                padding: 45px 0 0px 0;
                border-top: 1.5px solid #ffffff;
                text-align: center;
                width: 100%;
                a {
                    color: #ffffff;
                    font-weight: bold;
                    cursor: pointer;
                    text-decoration: underline;
                    &:hover {
                        color: #ffffff;
                    }
                }
            }
            amplify-authenticator {
                flex: 1;
            }
        }
        >div {
            &:first-child {
                >div {
                    display: flex;
                    flex-direction: column;
                    margin-top: 10px;
                    width: 300px;
                    padding-left: 16px;
                    margin-bottom: 25px;
                    label {
                        font-size: 16px;
                        color: #FFFFFF;
                        letter-spacing: 1.43px;
                        line-height: 14px;
                    }
                }
                img {
                    // margin: 50px;
                    margin: 35px auto;
                }
                display: flex;
                justify-content: center;
                align-items: center;
                /*  height: 100vh;*/
                // padding: 20px 40px 100px;
                flex-direction: column;
                mat-form-field {
                    /*  margin: 10px;*/
                }
            }
            article {
                width: 300px;
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                span {
                    font-size: 16px;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 14px;
                }
            }
        }
        mat-form-field {
            input {
                color: #fff;
                padding: 5px;
                font-size: 18px;
                color: #FFFFFF;
                letter-spacing: 1.18px;
                line-height: 16px;
            }
            .mat-form-field-flex {
                .mat-form-field-label {
                    color: #fff !important;
                }
            }
        }
        input+span {
            label {
                color: #fff !important;
            }
        }
        .amplify-container {
            .amplify-form-header {
                display: none;
            }
            .amplify-form-text {
                font-family: arial;
                font-size: 16px;
                color: #ffffff;
                margin-bottom: 18px;
            }
            .amplify-signin-username,
            .amplify-signin-username>div,
            .amplify-signin-password,
            .amplify-form-row {
                position: relative;
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid #ffffff;
                margin-bottom: 45px;
                label,
                span {
                    font-family: arial;
                    font-size: 16px;
                    color: #ffffff;
                }
                label {
                    margin-bottom: 9px;
                }
                span {
                    position: absolute;
                    bottom: -150px;
                    a {
                        position: relative;
                        right: -20px;
                        cursor: pointer;
                    }
                }
                input {
                    border: none;
                    margin-bottom: 9px;
                    background: transparent;
                    color: white;
                    font-size: 14px;
                    &:active,
                    &:focus {
                        outline: none;
                    }
                }
            }
            .amplify-signin-username>div {
                margin-bottom: 0;
                border-bottom-color: transparent;
            }
            .amplify-form-actions {
                button {
                    padding: 15px 20px;
                    min-width: 225px;
                    border-radius: 25px;
                    border: 0;
                    outline: 0;
                    cursor: pointer;
                    width: 300px;
                    background: transparent;
                    border: 1px solid #fff;
                    font-size: 16px;
                    color: #fff;
                    letter-spacing: 2px;
                    &.active {
                        color: #00338D;
                        background: #fff;
                    }
                }
                .amplify-form-cell-left {
                    display: none;
                }
            }
            .amplify-alert-body {
                // display: none;
                display: flex;
                align-items: center;
                color: orange;
                margin-top: 18px;
                .amplify-alert-message {
                    margin: 0 5px;
                    +a {
                        cursor: pointer;
                    }
                }
            }
        }
    }
    button[login] {
        padding: 15px 20px;
        min-width: 225px;
        border-radius: 25px;
        border: 0;
        outline: 0;
        cursor: pointer;
        width: 300px;
        background: transparent;
        border: 1px solid #fff;
        font-size: 16px;
        color: #fff;
        letter-spacing: 2px;
        &.active {
            color: rgba(0, 0, 255, 0.9);
            background: #fff;
        }
    }
    button {
        mat-icon {
            font-size: 20px;
            color: #FFF;
        }
    }
}

portal-logout {
    display: block;
    background-image: url("/assets/images/login-bg-new.jpg");
    background-size: cover;
    div[journey-login] {
        /*  background: rgba(0, 0, 255, 0.8);*/
        height: 100vh;
        position: relative;
        div[login-section] {
            position: absolute;
            top: 10%;
            left: 10%;
            opacity: 0.85;
            background-image: linear-gradient(180deg, #00338D 0%, #002E7F 78%);
            min-width: 435px;
            min-height: 575px;
            span[text] {
                font-size: 16px;
                color: #FFFFFF;
                display: block;
                padding: 45px 0 0px 0;
                border-top: 1.5px solid #ffffff;
                text-align: center;
                width: 100%;
                a {
                    color: #ffffff;
                    font-weight: bold;
                    cursor: pointer;
                    text-decoration: underline;
                    &:hover {
                        color: #ffffff;
                    }
                }
            }
            amplify-authenticator {
                flex: 1
            }
        }
        >div {
            &:first-child {
                >div {
                    display: flex;
                    flex-direction: column;
                    margin-top: 10px;
                    width: 300px;
                    padding-left: 16px;
                    margin-bottom: 25px;
                    label {
                        font-size: 16px;
                        color: #FFFFFF;
                        letter-spacing: 1.43px;
                        line-height: 14px;
                    }
                }
                img {
                    // margin: 50px;
                    margin: 35px auto;
                }
                display: flex;
                justify-content: center;
                align-items: center;
                /*  height: 100vh;*/
                // padding: 20px 40px 100px;
                flex-direction: column;
                mat-form-field {
                    /*  margin: 10px;*/
                }
            }
            article {
                width: 300px;
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                span {
                    font-size: 16px;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 14px;
                }
            }
        }
        mat-form-field {
            input {
                color: #fff;
                padding: 5px;
                font-size: 18px;
                color: #FFFFFF;
                letter-spacing: 1.18px;
                line-height: 16px;
            }
            .mat-form-field-flex {
                .mat-form-field-label {
                    color: #fff !important;
                }
            }
        }
        input+span {
            label {
                color: #fff !important;
            }
        }
        .amplify-container {
            .amplify-form-header {
                display: none;
            }
            .amplify-form-text {
                font-family: arial;
                font-size: 16px;
                color: #ffffff;
                margin-bottom: 18px;
            }
            .amplify-signin-username,
            .amplify-signin-username>div,
            .amplify-signin-password,
            .amplify-form-row {
                position: relative;
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid #ffffff;
                margin-bottom: 45px;
                label,
                span {
                    font-family: arial;
                    font-size: 16px;
                    color: #ffffff;
                }
                label {
                    margin-bottom: 9px;
                }
                span {
                    position: absolute;
                    bottom: -150px;
                    a {
                        position: relative;
                        right: -20px;
                        cursor: pointer;
                    }
                }
                input {
                    border: none;
                    margin-bottom: 9px;
                    background: transparent;
                    color: white;
                    font-size: 14px;
                    &:active,
                    &:focus {
                        outline: none;
                    }
                }
            }
            .amplify-signin-username>div {
                margin-bottom: 0;
                border-bottom-color: transparent;
            }
            .amplify-form-actions {
                button {
                    padding: 15px 20px;
                    min-width: 225px;
                    border-radius: 25px;
                    border: 0;
                    outline: 0;
                    cursor: pointer;
                    width: 300px;
                    background: transparent;
                    border: 1px solid #fff;
                    font-size: 16px;
                    color: #fff;
                    letter-spacing: 2px;
                    &.active {
                        color: #00338D;
                        background: #fff;
                    }
                }
                .amplify-form-cell-left {
                    display: none;
                }
            }
            .amplify-alert-body {
                // display: none;
                display: flex;
                align-items: center;
                color: orange;
                margin-top: 18px;
                .amplify-alert-message {
                    margin: 0 5px;
                    +a {
                        cursor: pointer;
                    }
                }
            }
        }
    }
    button[login] {
        padding: 15px 20px;
        min-width: 225px;
        border-radius: 25px;
        border: 0;
        outline: 0;
        cursor: pointer;
        width: 300px;
        background: transparent;
        border: 1px solid #fff;
        font-size: 16px;
        color: #fff;
        letter-spacing: 2px;
        &.active {
            color: rgba(0, 0, 255, 0.9);
            background: #fff;
        }
    }
    button {
        mat-icon {
            font-size: 20px;
            color: #FFF;
        }
    }
}


portal-error {
    display: flex;
    justify-content: center;
    error-notauthorized,
    error-network,
    error-forbidden,
    error-pagenotfound {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 1200px;
        min-width: 1024px;
        margin-top: 15%;
        div[left] {
            flex: 1;
            padding: 25px;
            border-right: 2px solid #ddd;
            .title {
                background-color: #00338d;
                height: 67px;
                display: flex;
                align-items: center;
                >div {
                    margin-left: 15px;
                    cursor: pointer;
                    >img {
                        cursor: pointer;
                    }
                }
            }
            .left-content {
                >p {
                    color: #111111;
                    font-weight: bold;
                    font-size: 22px;
                    letter-spacing: 0;
                    line-height: 26px;
                    margin: 0;
                    margin-top: 35px;
                    margin-bottom: 15px;
                }
                >span {
                    font-size: 16px;
                    color: #111111;
                    letter-spacing: 0;
                    line-height: 24px;
                    a {
                        text-decoration: none;
                        color: #00338D;
                        font-size: 16px;
                        &:hover {
                            text-decoration: none;
                            color: #00338D;
                        }
                    }
                }
                >a {
                    text-decoration: none;
                    margin-top: 24px;
                    color: #00338D;
                    display: block;
                    font-size: 16px;
                    &:hover {
                        text-decoration: none;
                        color: #00338D;
                    }
                }
                @media (min-width: 768px) and (max-width: 1024px) {
                    >p {
                        font-size: 14px;
                        line-height: 14px;
                        margin-top: 15px;
                        margin-bottom: 5px;
                    }
                    >span {
                        font-size: 12px;
                        line-height: 20px;
                        a {
                            font-size: 12px;
                        }
                    }
                    >a {
                        font-size: 14px;
                        margin-top: 16px;
                    }
                }
                @media (min-width: 1025px) and (max-width: 1200px) {
                    >p {
                        font-size: 20px;
                        line-height: 24px;
                        margin-top: 15px;
                        margin-bottom: 10px;
                    }
                    >span {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
            }
        }
        div[right] {
            flex: 1;
            padding-left: 65px;
            >p {
                margin: 0;
                font-size: 200px;
                color: #00338D;
                letter-spacing: 4px;
                line-height: 1;
                font-weight: bold;
                >img {
                    height: 160px;
                    margin-right: 10px;
                }
            }
            >span {
                opacity: 0.35;
                font-size: 16px;
                color: #00338D;
                letter-spacing: 5px;
                text-transform: uppercase;
                margin-left: 15px;
            }
            @media (min-width: 768px) and (max-width: 1024px) {
                >p {
                    font-size: 120px;
                    >img {
                        height: 100px;
                    }
                }
                >span {
                    margin-left: 9px;
                    font-size: 11px;
                }
            }
            @media (min-width: 1025px) and (max-width: 1200px) {
                >p {
                    font-size: 170px;
                    >img {
                        height: 130px;
                    }
                }
                >span {
                    margin-left: 12px;
                    font-size: 14px;
                }
            }
        }
    }
}

//Login page css
// .mat-form-field-flex {
//     .mat-form-field-label {
//         opacity: 0.6;
//         font-size: 18px;
//         color: #FFFFFF;
//         letter-spacing: 1.18px;
//     }
// }
// .mat-form-field-appearance-legacy .mat-form-field-underline {
//     background-color: #FFFFFF;
// }
//end of login page
// Class Roaster

/* Text */

.text {
    &-danger {
        color: #D0021B;
    }
    &-gray {
        color: #888888;
    }
    &-primary {
        color: #00338D;
    }
    &-black {
        color: #111111;
    }
}


/* Notification */

.notification {
    padding: 15px;
    background: #FFFFFF;
    border: 1.5px solid #00338D;
    margin: 15px 20px 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    &[show="false"] {
        display: none;
    }
    p {
        margin: 0;
        &:first-child {
            font-weight: bold;
            margin-bottom: 10px;
        }
        &:nth-child(2) {
            display: flex;
            align-items: center;
            color: #111111;
            >img {
                margin-right: 10px;
            }
        }
        span[red] {
            color: #D0021B;
        }
    }
    &.danger {
        border-color: #D0021B;
        // background: rgba(208, 2, 27, 0.05);
    }
    &.warning {
        border-color: #EAAA00;
    }
    &.success {
        border-color: #009A44;
    }
    button {
        padding: 15px 20px;
        border: none;
        font-size: 18px !important;
        font-weight: bold;
        display: flex;
        align-items: center;
        &.btn-primary {
            color: #FFFFFF !important;
            background: #00338D;
            &:disabled,
            &.disabled {
                background: rgba(0, 51, 141, 0.5);
            }
        }
        &.btn-success {
            color: #FFFFFF !important;
            background: #009A44 !important;
        }
        i {
            font-size: 21px;
            margin-left: 10px;
        }
    }
}


/* Popover */

.popover {
    position: absolute;
    width: auto;
    top: -185px;
    right: 10px;
    background: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    border-radius: 2px;
    padding: 15px 20px;
    display: flex;
    &[show="false"] {
        display: none;
    }
    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        width: 0;
        height: 0;
        border-top: solid 14px #ffffff;
        border-left: solid 14px transparent;
        border-right: solid 14px transparent;
    }
    >div {
        justify-content: space-around;
        &:first-child {
            display: flex;
            flex-direction: column;
            span:first-child {
                font-size: 16px;
                color: #111111;
                letter-spacing: 0;
                text-align: center;
                line-height: 22px;
                font-weight: bold;
            }
            article {
                display: flex;
                justify-content: center;
                margin-top: 15px;
                button {
                    align-items: center;
                    justify-content: center;
                    font-weight: normal;
                    border: 0;
                    padding: 5px 0;
                    outline: 0;
                    font-size: 14px;
                    cursor: pointer;
                    &:first-child {
                        background: transparent;
                        color: #00338D;
                        margin-right: 10px;
                    }
                    &:nth-child(2) {
                        color: #FFF;
                        background: #00338D;
                        width: 130px;
                    }
                }
            }
        }
    }
}


/* module-view */

.module-view {
    border-bottom: solid 1px rgba(153, 153, 153, 0.30);
    padding-bottom: 10px;
    padding-right: 20px;
    position: relative;
    margin-top: 10px;
    &[blank] {
        color: #888888 !important;
    }
    i {
        position: absolute;
        right: 0;
        font-size: 18px;
        color: #888888 !important;
    }
}


/* Panel */

.panel {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 20px;
    // border: 1px solid #ddd;
    border-top: 4px solid #00338D;
    background: #ffffff;
    .panel-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        .panel-title {
            display: flex;
            align-items: center;
            >span {
                padding: 10px;
                i {
                    color: #00338D;
                }
            }
            >p {
                color: #111111;
                font-size: 20px;
                font-weight: bold;
                padding: 10px;
                margin: 0;
            }
        }
        .panel-search {
            display: flex;
            width: 350px;
            margin-right: 10px;
            i {
                color: #999999;
                font-weight: normal;
                font-size: 30px;
                cursor: pointer;
            }
            form[search] {
                flex: 1;
                input {
                    padding: 5px 10px;
                    border: 0;
                    color: #999999;
                    font-size: 16px;
                    opacity: 0.7;
                    outline: 0;
                    width: 92%;
                }
            }
        }
    }
}


/* Table */

.table {
    width: 100%;
    border-spacing: 0;
    thead {
        th {
            font-size: 13px;
            padding: 13px 10px;
            color: #888888;
            text-transform: uppercase;
            vertical-align: middle;
            text-align: left;
            width: 10%;
            border-bottom: 5px solid #f5f5f5;
            border-top: 2px solid #f5f5f5;
            &:first-child {
                padding-left: 20px;
            }
            i {
                vertical-align: middle;
                vertical-align: middle;
                cursor: pointer;
                color: #888888;
                &.active {
                    transform: rotate(180deg);
                }
            }
            >img {
                margin-left: 7px;
                &.active {
                    transform: rotate(180deg);
                }
            }
        }
    }
    tbody {
        td {
            border-bottom: 5px solid #f5f5f5;
            padding: 20px 10px;
            text-align: left;
            border-right: 0;
            border-left: 0;
            vertical-align: baseline;
            width: 10%;
            &:first-child {
                padding-left: 20px;
            }
            &:last-child {
                padding-right: 20px;
            }
            &.xl {
                width: 23% !important;
            }
            &.lg {
                width: 17% !important;
            }
            >div {
                display: flex;
                justify-content: flex-start;
                &:last-child {
                    justify-content: space-between;
                }
                >div {
                    display: flex;
                    flex-direction: column;
                    img {
                        cursor: pointer;
                    }
                }
            }
            &.red {
                border-left: 3px solid #F68D2E;
            }
        }
    }
}

.cdk-global-overlay-wrapper {
    justify-content: flex-end !important;
}

.mat-dialog-container {
    border-radius: 0 !important;
}

.class-roster-dialog.cdk-overlay-pane {
    width: calc(100% - 213px) !important;
    max-width: 100% !important;
    overflow-y: scroll;
    .mat-dialog-container {
        padding: 0;
        background: #f5f5f5;
        min-height: 100vh;
    }
}

.notification-submit-dialog.cdk-overlay-pane {
    margin-left: auto;
    margin-right: auto;
    .mat-dialog-container {
        border: 1px solid #000;
        .mat-dialog-actions {
            display: block;
            margin-top: 10px;
            text-align: right;
        }
        .buttonTextYes {
            background-color: #00338d;
            color: white;
            margin-left: 10px;
        }
        .buttonTextNo {
            background-color: white;
            color: #00338d;
        }
        .body {
            font-weight: bold;
        }
    }
}

.notification-cancel-dialog.cdk-overlay-pane {
    margin-left: auto;
    margin-right: auto;
    .mat-dialog-container {
        border: 1px solid #000;
        .mat-dialog-actions {
            display: block;
            margin-top: 10px;
        }
        .buttonTextNo {
            background-color: #00338d;
            color: white;
            float: right;
            margin-left: 10px;
        }
        .buttonTextYes {
            background-color: white;
            color: #00338d;
            float: right;
        }
        .body {
            font-weight: bold;
        }
    }
}

/* Custom styles for the document Url dialog container */
.custom-confirm-url-dialog.cdk-overlay-pane {
    margin-left: auto;
    margin-right: auto;
    .mat-dialog-container {
        border: 1px solid #000;
        .mat-dialog-actions {
            display: block;
            margin-top: 10px;
        }
        .buttonTextNo {
            background-color: #00338d;
            color: white;
            float: right;
            margin-left: 10px;
        }
        .body {
            font-weight: bold;
        }
    }
}

.custom-confirm-url-dialog .mat-dialog-title {
    word-wrap: break-word;
    color: #00338d;
    font-size: 18px;

}

.dialog-close {
    cursor: pointer;
    color: #888888;
    position: absolute;
    right: 30px;
    top: 20px;
    z-index: 9999;
}

// End of Class Roaster
div[header-summary] {
    // max-width: 1440px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    height: 60px;
    border-bottom: 1.5px solid #E5E5E5;
    >span {
        color: #00338D;
        font-size: 16px;
        font-weight: bold;
    }
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;
}

// Comment and Comment box
.comment {
    margin: 10px 0;
    position: relative;
    >span {
        color: #00338D;
        font-size: 14px;
        cursor: pointer;
        align-items: center;
        position: relative;
        // &:after {
        //     content: '';
        //     border: 5px solid transparent;
        //     border-top-color: #00338D;
        //     position: absolute;
        //     top: 50%;
        //     transform: translateY(-2.5px);
        //     margin-left: 5px;
        // }
    }
    .comment-box {
        background: #FFFFFF;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
        border-radius: 2px;
        min-width: 300px;
        position: absolute;
        z-index: 9;
        &[show="false"] {
            display: none;
        }
        >div[comment-box-header] {
            padding: 20px;
            display: flex;
            justify-content: space-between;
            border-bottom: 2px solid #e5e5e5;
            >span {
                color: #00338D;
                display: flex;
                align-items: center;
                >img {
                    margin-right: 10px;
                }
            }
            >i {
                color: #888888;
                cursor: pointer;
                font-size: 18px;
            }
        }
        >div[comment-box-body] {
            padding: 20px;
            >p {
                font-size: 14px;
                color: #111111;
            }
            div[comment-info] {
                display: flex;
                >img {
                    margin-right: 10px;
                }
                >div {
                    p {
                        margin: 0;
                        font-size: 14px;
                        font-weight: bold;
                        color: #111111;
                        span {
                            color: #00338D;
                            font-weight: normal;
                            margin-left: 5px;
                            margin-right: 2.5px;
                            cursor: pointer;
                            &:last-child {
                                margin-left: 2.5px;
                                margin-right: 0;
                            }
                        }
                    }
                    >span {
                        color: #888888;
                        font-size: 12px;
                    }
                }
            }
            div[comment-chip] {
                display: flex;
                .chip:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

// Chip
.chip {
    background: rgba(0, 51, 141, 0.04);
    border-radius: 16px;
    padding: 7px 10px;
    color: #00338D;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    i {
        color: #00338D;
        cursor: pointer;
        margin-left: 7px;
    }
}

//buttons
button {
    padding: 14px 20px;
    border: none;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.btn-primary {
        color: #FFFFFF;
        background: #00338D;
        &:disabled,
        &.disabled {
            background: rgba(0, 51, 141, 0.5);
        }
    }
    &.btn-success {
        color: #FFFFFF;
        background: #009A44;
    }
    i {
        font-size: 21px;
        margin-left: 10px;
    }
}

.buttons {
    display: flex;
    justify-content: flex-end !important;
    // margin-top: -40px;
    margin-bottom: 20px;
    position: relative;
    .btn:first-child {
        margin-right: 10px;
    }
    .btn {
        padding: 5px 20px;
        border: none;
        font-size: 14px !important;
        font-weight: bold;
        display: flex;
        align-items: center;
        background: transparent;
        color: #00338D;
        position: relative;
        cursor: pointer;
        &:active,
        &:focus {
            outline: none;
        }
        &[show="false"] {
            display: none;
        }
        &.btn-primary {
            background: #00338D !important;
            color: #ffffff;
        }
        &.btn-success-flat {
            color: #009A44;
            >i {
                margin-left: 10px;
            }
        }
    }
    .popover {
        top: 0;
        left: 0;
        transform: translate(-40%, calc(-100% - 14px));
    }
}

.profile-dropdown {
    background: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    border-radius: 2px;
    width: 160px;
    position: absolute !important;
    top: 80%;
    right: 0;
    z-index: 99999;
    display: block;
    margin: 0 !important;
    &[show="false"] {
        display: none;
    }
    span {
        display: block;
        padding: 20px 25px;
        color: #00338d !important;
        font-size: 16px !important;
        cursor: pointer;
        margin: 0 !important;
        &:not(:last-child) {
            border-bottom: 1.5px solid #e5e5e5;
        }
        &[disabled="true"] {
            color: #999999 !important;
        }
        &[isDisabled="true"] {
            color: #999999 !important;
        }
    }
}

.readonly {
    pointer-events: none;
}

.readonly-wrapper {
    cursor: not-allowed;
}

.readonly-wrapper .readonly-block {
    pointer-events: none;
    cursor: not-allowed;
}

.time_filter{
    input[type="checkbox"]:disabled{
    background: #eeeeee !important;
    }
}

.vertical-align-middle {
    vertical-align: middle;
}

.background-white {
    background-color: #FFFFFF;
}

.bottom-grey-border-override {
    border-bottom: 1px solid #aaa !important;
    padding: 5px 0px !important;
}

.list-item {
    display: list-item;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 18px;
    vertical-align: middle;
    border-top: 6px solid;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    cursor: pointer;
    color: #00338D;
}
.height-2px {
    height: 2px !important;
}
.pointer{
    cursor: pointer;
}
.expand-button{
    font-size: 14px;
    color: #00338D;
    cursor: pointer;
    &:hover {
        color: #1d4ca0;
    }
}
.display-flex{
    display: flex;
    align-items: center;
} 
.pipe{
    color: #00338D;
}
.total-missed-label{
    display: flex;
    align-items: center;
}
.bold-text{
    font-weight: 600;
}
.time-missed-box{
    display: flex !important;
    justify-content: space-between !important;
}
.total-time-count{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.background-red{
    background: $red;
}
.background-green{
    background: $green;
}
.background-white-transparent{
    background: rgba(255,255,255,0.3);
}
.error-snackbar {
    @extend .background-red;
    @extend .color-white;
    @extend .font-weight-bold;
  .mat-button{
       @extend .color-black;
       @extend .font-weight-bold;
       @extend .background-white-transparent;
  }
}
.success-snackbar {
    @extend .background-green;
    @extend .color-white;
    @extend .font-weight-bold;
  .mat-button{
       @extend .color-black;
       @extend .font-weight-bold;
       @extend .background-white-transparent;
  }
}
.min-width-50
{
    min-width: 50px !important;
}
.events-table
{
    overflow: auto;
    .mat-form-field{
        width: 100% !important;
        .mat-form-field-infix{
            width: 100% !important;
        }
        .mat-form-field-type-mat-date-range-input .mat-form-field-infix{
            width: 100% !important;
        }
    }
}

.dashboard-head{
    display: flex;
    justify-content: space-between;
    padding: 18px;
    .name{
        display: flex;
    }
}

.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
    cursor: not-allowed;
}
.border-top-0{
    .mat-form-field-infix{
        border-top: 0;
    }
}

.mat-tab-group.mat-tab-fill-height .mat-tab-body-wrapper {
    flex: 1 1 100%;
}
 
.border-bottom-grey {
    border-bottom: 1px solid #eee;
}

.w-95{
    width:95%;
}

.w-100{
    width:100%;
}

.w-fit-content{
    width:fit-content;
}