$spacing-xs: 5px;
$spacing-sm: 10px;
$spacing-md: 15px;
$spacing-lg: 20px;
$spacing-xl: 25px;
$spacing-xxl: 30px;
// Colors
$shade-gray: #888888;
$gray-light: #A7A7A7;
$gray-lighter: #eee;
$light-silver: #D8D8D8;
$cyan-blue: #00338D;
$solitude: #F4F6FA;
$black: #000000;
$white: #FFFFFF;
$red: #f70606;
$green: green;
$purple: #7911b4;
